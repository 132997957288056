$primary-color: #2BA6C8;
$secondary-color: #FD64A0;
$background-color: #FCFCFC;
$background-primary-color: #FAFDFF;
$text-default-color: #7D8D96;
$text-light-color: #96A4AC;
$text-extralight-color: #BEC4C7;
$border-color: #E9E9E9;
$danger-color: #D0021B;
$success-color: #3ABA7C;
$default-font-family: 'Museo Sans', Helvetica, sans-serif;