@font-face {
  font-family: 'Museo Sans';
  src: url('/static/fonts/museosans-500.woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('/static/fonts/museosans-700.woff');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: fixed;
}

body, input, textarea {
  font-family: $default-font-family;
  margin: 0;
}

a {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;
  border: 0;
}

.hidden {
  display: none;
}

.one-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.centered {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-switch {
  display: block;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 8px;
  color: $primary-color;
  border: 1px solid $border-color;
  float: right;
  margin: 5px;
  box-shadow: 0 2px 0 0 $border-color;
  transition: all 300ms;
  cursor: pointer;
  &:hover {
    border: 1px solid darken($border-color, 10%);
    color: darken($primary-color, 5%);
  }
  &:active, &.is-active {
    background: #f7f7f7;
    transform: translateY(2px);
    box-shadow: 0 -1px 0 0 $border-color;
  }
}

.btn {
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 25px;
  height: 50px;
  line-height: 50px;
  outline: none;
  cursor: pointer;
  transition: all 300ms;
  color: white;
  word-break: break-word;
  white-space: normal;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: $primary-color;
  transition: background-color 300ms;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    background-color: lighten($primary-color, 3%);
  }

  &:active:not(:disabled) {
    background-color: darken($primary-color, 3%);
  }
}

@keyframes progressBarStripes {
  from {
    background-position: 0 100px;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes fadeOutToNone {
  0% {
    display: block;
    opacity: 1;
  }

  99.9% {
    display: block;
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

.loading {
  position: fixed;
  left: 0;
  right: 0;
  height: 4px;
  overflow: hidden;
  z-index: 9999;
  top: 0;

  &.stop {
    animation: fadeOutToNone 0.5s forwards;
  }
  .progress {
    height: 100%;
    animation: progressBarStripes 1s linear infinite;
    background-color: $secondary-color;
    background-size: 100px 100px;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.25) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 75%, transparent 75%, transparent);
  }
}

.flex-row {
  display: flex;
  justify-content: space-between;
  .search-box {
    position: relative;
    width: 49%;
  }
}

button.find-flight {
  margin-top: 25px;
  margin-bottom: 25px;
  color: #fff;
  border: 0;
  border-bottom: 4px solid #2B662A;
  background: linear-gradient(180deg, #48AD47 0%, #398A39 100%);
  border-radius: 7px;
  padding: 16px 60px;
  font-weight: bold;
  font-size: 25px;
}
.home-find-block {
  width: 100%;
  text-align: center;
}

.flights-with-stop {
  width: 200px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  text-align: center;
}
