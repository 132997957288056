
@keyframes pulse {
  0%{
    background-position: -350px 0
  }
  100%{
    background-position: 350px 0
  }
}

.loading-line {
  height: 10px;
  margin: 10px;
  animation: pulse 0.75s infinite ease-in-out;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 700px 20px;
  width: 75%;

  &.shorter{
    width: 50%;
  }
  &.panel-filter-loading {
    position: absolute;
    margin: 0;
    top: 19px;
    left: 20px;
    width: 40%;
  }

  &.panel-search-loading {
    position: absolute;
    margin: 0;
    top: 19px;
    right: 20px;
    width: 20px;
  }
}
