@keyframes redirect-dot-loading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}
.logo-redirect {
  .logo-from {
    display: inline-block;
    vertical-align: middle;
    margin: 20px;
  }

  .logo-loading {
    display: inline-block;
    letter-spacing: 0;
    margin: 20px;
    vertical-align: middle;

    .dot {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin: 5px;
      background-color: $primary-color;
      border-radius: 100%;
      animation: redirect-dot-loading 1s infinite;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 0.1s;
      }

      &:nth-child(3) {
        animation-delay: 0.2s;
      }
    }
  }

  .logo-to {
    display: inline-block;
    vertical-align: middle;
    margin: 20px;
  }
}