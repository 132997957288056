@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.preview {
  position: absolute;
  right: 50px;
  bottom: 50px;
  top: 50px;
  left: 50px;
  display: flex;
  align-items: center;
  z-index: 3;

  h1 {
    font-weight: bold;
    color: $primary-color;
    font-size: 64px;
    strong {
      color: $secondary-color;
    }
  }
}

.panel-logo {
  position: absolute;
  left: 45px;
  top: 25px;
  width: 50px;
  height: 54px;
  z-index: 2;
  background: transparent url('/static/images/logo.svg');
  background-size: 50px 54px;
  cursor: pointer;
  transition: transform 300ms;

  &.is-preview {
    background-size: 100px 108px;
    width: 100px;
    height: 108px;
  }

  &:hover {
    transform: rotateY(360deg);
  }
}
.panel {
  position: absolute;
  width: 400px;
  top: 100px;
  left: 45px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  z-index: 2;

  background-color: rgba(#fff,0.90);
  border: 1px solid darken($border-color, 5%);
  box-shadow: 0 2px 42px 0 rgba(0, 0, 0, 0.05), 0 0 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  transform: translateY(0);
  transition: transform 500ms;

  .panel-header {
    background: $background-primary-color;
    border-radius: 6px 6px 0 0;
    .panel-nav {
      padding: 15px 0 0 15px;
      a {
        color: $secondary-color;
        font-size: 16px;
        font-weight: bold;
        .icon {
          font-size: 12px;
        }
        &:hover {
          color: darken($secondary-color, 10%);
        }
      }
    }
    .panel-header-info {
      padding: 10px 15px;
      position: relative;
      &.panel-header-large {
        height: 220px;
      }
      &.panel-read-more {
        height: 135px;
        margin-bottom: 32px;
        .readmore-gradient {
          position: absolute;
          display: block;
          width: 100%;
          height: 40px;
          left: 0;
          bottom: -20px;
          background: linear-gradient(0deg, rgba(250,253,255,1) 0%, rgba(250,253,255,0) 100%);
        }
        .readmore-link {
          position: absolute;
          background-color: rgba(250,253,255, 1);
          display: block;
          margin-top: -3px;
          right: 15px;
          height: 30px;
          padding: 7px 0;
          line-height: 4px;
          font-size: 14px;
          left: 15px;
          bottom: -50px;
          color: $primary-color;
          text-decoration: none;
          cursor: pointer;
          border: 0;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      &.full {
        &.panel-read-more {
          height: auto;
          margin-bottom: 0;
          .readmore-gradient {
            display: none;
          }
          .readmore-link {
            display: none;
          }
        }
      }
    }
    .panel-back {
      width: 80px;
    }
    h1 {
      font-weight: bold;
      color: $primary-color;
      font-size: 24px;
      margin-top: 0px;
      margin-bottom: 5px;
      strong {
        color: $secondary-color;
      }
    }
    p {
      color: $text-default-color;
      font-size: 14px;
      line-height: 18px;
      margin-top: 3px;
      margin-bottom: 3px;
    }


  }

  .panel-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    z-index: 1001;
    background: $background-primary-color;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .panel-tabs {
    background: $background-primary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    border-bottom: 1px solid $border-color;

    .panel-tabs-arrow {
      width: 30px;
      text-align: center;
      font-size: 10px;
      color: $text-default-color;
      cursor: pointer;
      &:hover:not(.disabled) {
        color: $primary-color;
      }
      &.disabled {
        opacity: 0.3;
        cursor: default;
      }
    }

    .panel-tab {
      color: $text-default-color;
      font-size: 12px;
      text-align: center;
      padding: 5px;
      width: 100px;
      margin: 0 2px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 0;
      cursor: pointer;
      z-index: 98;
      background-color: darken($background-primary-color, 1%);
      transform: translateY(1px);
      margin-top: 1px;
      padding-bottom: 4px;
      margin-bottom: 1px;

      &.active {
        border: 1px solid $border-color;
        border-bottom: $background-primary-color;
        background-image: linear-gradient(-180deg, darken($background-primary-color, 2%) 0%, $background-primary-color 100%);
        margin-top: 0;
        padding-bottom: 5px;
        margin-bottom: 0;
      }

      &:hover:not(.active) {
        background-color: darken($background-primary-color, 2%);
        padding-bottom: 4px;
        margin-bottom: 1px;
      }

      em {
        display: block;
        margin-top: 5px;
        font-size: 13px;
        font-style: normal;
        color: $text-extralight-color;
        span {
          color: $primary-color;
          padding-right: 5px;
        }
        .icon {
          font-size: 9px;
        }
      }
    }
  }

  .panel-filter {
    position: relative;
    z-index: 100;
    .search-icon {
      color: $text-light-color;
      font-size: 18px;
      position: absolute;
      right: 15px;
      top: 15px;
      width: 24px;
      text-align: right;
      height: 24px;
    }
    input {
      -webkit-appearance: none;
      box-shadow: none !important;
      background: #fff;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      height: 45px;
      line-height: 45px;
      width: calc(100% - 40px);
      color: $text-default-color;
      outline: none;
      font-size: 14px;
      padding: 0px 20px;
      transition: box-shadow 300ms;

      &::placeholder {
        color: $text-extralight-color;
      }
    }
  }

  .panel-content {
    flex: 1;
    border-bottom-right-radius: 6px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 101;
    -webkit-overflow-scrolling: touch;

    &.is-loading {
      overflow-y: hidden;
    }
  }

  .panel-footer {
    position: relative;
    padding: 10px 20px;
    color: $text-default-color;
    border-top: 1px solid $border-color;
    font-size: 14px;
    background: $background-primary-color;
    border-radius: 0 0 6px 6px;

    .top-line {
      display: flex;
      justify-content: space-between;
    }

    .hotel-car-prices {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .hotel-prices {
        width: 49%;
        background-color: #5cb9d5;
        color: #ffffff;
        border-radius: 6px;
      }

      .car-prices {
        width: 49%;
        background-color: #fc5f9e;
        color: #ffffff;
        border-radius: 6px;
      }
    }

    .check-prices {
      background-color: #5d9432;
      color: #ffffff;
      &:hover {
        color: #ffffff;
      }
      .icon {
        vertical-align: -2px;
      }
    }

    .panel-sort-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 47, 81, 0.05);
      z-index: 1000;
      animation: fade-in 300ms ease-out;
      animation-fill-mode: forwards;
    }
    button {
      font-family: $default-font-family;
      background: #FFFFFF;
      border: 1px solid $border-color;
      box-shadow: 0 2px 0 0 $border-color;
      border-radius: 2px;
      color: $text-default-color;
      outline: none;
      font-size: 14px;
      line-height: 34px;
      padding: 0px 10px;
      transition: all 300ms;
      .icon {
        font-size: 22px;
        vertical-align: -7px;
        margin-right: 4px;
      }
      b {
        font-weight: normal;
        color: $secondary-color;
      }
      cursor: pointer;
      &:hover {
        border: 1px solid darken($border-color, 10%);
        color: darken($text-default-color, 10%);
      }
      &:active, &.is-active {
        background: #f7f7f7;
        transform: translateY(2px);
        box-shadow: 0 -1px 0 0 $border-color;
      }
    }
  }

  .panel-sort {
    z-index: 1001;
    position: absolute;
    bottom: 50px;
    width: 200px;
    height: 400px;
    left: 20px;
    z-index: 99999;
    background: #FFFFFF;
    border: 1px solid $border-color;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07);
    border-radius: 4px 4px 4px 0;

    ul {
      list-style: none;
      padding: 10px 0;
      margin: 0;

      li {
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &.header {
          color: $text-extralight-color;
          font-size: 12px;
          padding: 15px 5px 8px 10px;
          font-weight: bold;
          text-transform: uppercase;
          &.is-first {
            padding-top: 0;
          }
        }
        &.item {
          font-size: 14px;
          color: $text-default-color;
          padding: 10px 5px 10px 35px;
          cursor: pointer;
          &:hover {
            background-color: $background-primary-color;
          }
        }
        &.is-active {
          color: $secondary-color;
          &:before {
            font-family: "directflights" !important;
            content: "\77";
            font-size: 12px;
            position: absolute;
            left: 14px;
            top: 9px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }

      }
    }
  }
}

@media (max-width: 700px) {
  .panel-logo {
    display: none;
  }
  .panel {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 0;
    box-shadow: none;
    border: 0;

    &.is-above {
      z-index: 100;
    }

    &.is-hidden {
      transform: translateY(100vh);
    }
    .panel-header {
      h1 {
        font-size: 24px;
        margin-bottom: 5px;
      }
      p {
        margin-top: 2px;
        margin-bottom: 2px;
      }
      .panel-header-info {
        padding: 10px;
      }
    }
  }
}


@media (max-width: 700px), (max-height: 800px) {
  .panel-logo {
    width: 40px;
    height: 43px;
    left: 20px;
    top: 15px;
    background-size: 40px 43px;
  }
  .panel {
    top: 70px;
    left: 20px;
    bottom: 10px;
    .panel-header {
      h1 {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 700px) {
  .panel {
    top: 0;
    left: 0;
  }
}
