.floating-prompt {
  position: fixed;
  background-color: #fff;
  padding: 24px;
  box-shadow: 0 4px 16px rgba(16, 31, 59, 0.16);
  z-index: 10;
  font-size: 16px;
  color: $text-default-color;
  opacity: 1;
  bottom: 62px;
  left: unset;
  right: 32px;
  width: 400px;
  transition: all .3s ease;

  .floating-prompt-close {
    position: absolute;
    right: 16px;
    top: 8px;
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  a {
    background: linear-gradient(65deg,#fd64a0,#DA552F);
    color: #fff !important;
    display: block;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none!important;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    justify-content: center;
    padding: 16px 16px;
    text-align: center;
    white-space: nowrap;
    box-shadow: 0 8px 24px rgba(32,43,54,.12);
    transition: all .3s ease;
    margin-top: 16px;
    font-size: 14px;
    &:hover {
      box-shadow: 0 6px 24px rgba(32,43,54,.4);
    }
  }
}

@media (max-width: 768px) {
  .floating-prompt  {
    width: calc(100% - 48px) !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    box-shadow: 0 -4px 16px rgba(16, 31, 59, 0.16) !important;
  }
}