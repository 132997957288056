@import './variables.scss';
@import './icons.scss';
@import './common.scss';
@import './loading.scss';
@import './airlines.scss';

@import './components/Visualization.scss';
@import './components/Panel.scss';
@import './components/PanelHome.scss';
@import './components/PanelError.scss';
@import './components/PanelRedirect.scss';
@import './components/List.scss';
@import './components/ReactSelect.scss';
@import './components/Nav.scss';
@import './components/App.scss';
@import './components/FloatingPrompt.scss';

@import './pages/index.scss';
