.panel-modal {
  position: absolute;
  width: 450px;
  height: 256px;
  left: 50%;
  top: 50%;
  margin-left: -225px;
  margin-top: -150px;
  text-align: center;
  z-index: 4;

  background: rgba(#fff,0.90);
  border: 1px solid darken($border-color, 5%);
  box-shadow: 0 2px 42px 0 rgba(0, 0, 0, 0.05), 0 0 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.xl {
    width: 550px;
    margin-left: -275px;
  }

  em {
    font-size: 14px;
    color: $text-default-color;
    margin: 3px;
    clear: both;
    display: block;
  }

  .panel-modal-top {
    display: flex;
    align-items: center;
    flex-grow: 1;
    &> div {
      width: 100%;
    }
    h1 {
      font-weight: bold;
      color: $primary-color;
      font-size: 24px;
      margin-top: 5px;
      margin-bottom: 3px;
      span {
        padding: 10px;
      }
    }
    p {
      color: $text-default-color;
      font-size: 18px;
      margin: 3px;
      line-height: 24px;
    }
  }
  .panel-modal-bottom {
    height: 140px;
    background-color: $background-primary-color;
    border-top: 1px solid darken($border-color, 5%);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .btn {
      margin: 20px auto;
    }
  }
}

@media (max-width: 560px) {
  .panel-modal {
    left: 10px;
    right: 10px;
    margin-left: 0;
    width: auto;

    &.xl {
      width: auto;
      margin-left: 0;
    }

    .logo {
      margin: 20px 40px;
    }
  }
}