@font-face {
  font-family: 'directflights';
  src: url('/static/fonts/directflights.woff?v=20190220');
  font-weight: normal;
  font-display: block;
  font-style: normal;
}


[data-icon]:before {
  font-family: "directflights" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  display: inline-block !important;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "directflights" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-caret-down:before {
  content: "\62";
}
.icon-caret-up:before {
  content: "\63";
}
.icon-caret-right:before {
  content: "\64";
}
.icon-caret-right.blue-icon {
  color: $primary-color;
}
.icon-caret-left:before {
  content: "\65";
}
.icon-dropdown:before {
  content: "\66";
}
.icon-arrow-right:before {
  content: "\61";
}
.icon-arrow-left:before {
  content: "\67";
}
.icon-takeoff:before {
  content: "\68";
}
.icon-landing:before {
  content: "\69";
}
.icon-aircraft:before {
  content: "\6b";
}
.icon-search:before {
  content: "\6c";
}
.icon-pause:before {
  content: "\6d";
}
.icon-play:before {
  content: "\6e";
}
.icon-refresh:before {
  content: "\6f";
}
.icon-close:before {
  content: "\6a";
}
.icon-gears:before {
  content: "\70";
}
.icon-list:before {
  content: "\71";
}
.icon-map:before {
  content: "\72";
}
.icon-marker:before {
  content: "\73";
}
.icon-menu:before {
  content: "\74";
}
.icon-options:before {
  content: "\75";
}
.icon-check:before {
  content: "\77";
}
.icon-sort-desc:before {
  content: "\76";
}
.icon-sort-asc:before {
  content: "\78";
}
.icon-switch:before {
  content: "\79";
}
.icon-light-arrow-right:before {
  content: "\2192";
}
