.visualization {
  position: relative;
  display: flex;
  transition: opacity 300ms;
  z-index: 1;

  &.is-centered {
    opacity: 0.4;
  }

  .map-svg {
    position: absolute;
    z-index: 3;

    &.mousedown {
      circle {
        cursor: -webkit-grabbing;
      }
    }

    &.mouseup {
      circle {
        cursor: -webkit-grab;
      }
    }

    &.clickable {
      circle {
        cursor: pointer;
      }
    }
  }

  .map-canvas {
    position: absolute;
    z-index: 1;
  }

  .map-tooltip {
    position: absolute;
    background: rgba(0,0,0,0.7);
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.25), 0 1px 10px 0 rgba(0,0,0,0.15);
    border-radius: 4px;
    width: 180px;
    height: 45px;
    z-index: 99999;
    display: flex;
    justify-content: space-between;
    text-shadow: 0 1px 1px rgba(0,0,0,0.32);
    color: white;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    transition: all 50ms;

    &.expandable {
      height: auto;
      align-items: stretch;
    }

    &.top {
      &::after {
        content: " ";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        margin-top: 1px;
        border-width: 5px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
      }
    }

    &.bottom {
      &::after {
        content: " ";
        position: absolute;
        top: -10px;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        margin-top: -1px;
        border-style: solid;
        border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
      }
    }

    .info {
      flex-grow: 1;
      font-size: 11px;
      margin: 5px 10px;
      b {
        font-size: 12px;
        font-weight: bold;
        display: block;
      }

      .one-line {
        width: 115px;
      }

      .icon {
        font-size: 9px;
        margin-left: 2px;
      }
    }

    .code {
      width: 45px;
      min-width: 45px;
      line-height: 45px;
      border-radius: 0 4px 4px 0;
      background: rgba(0,0,0,0.7);
      text-align: center;
      font-weight: bold;
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.light {
        background: rgb(255,255,255);
      }
    }
  }
}

.visualization-toggle {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background-color: #E2F6FF;
  border: 1px solid rgba(43,166,200,0.25);
  box-shadow:  0 0 7px 0 rgba(0, 0, 0, 0.05),  0 0 4px 0 rgba(0, 0, 0, 0.15), 0 2px 0 0 #BBE0EA;
  color: $primary-color;
  font-size: 24px;
  text-align: center;
  z-index: 9997;
  display: none;
  cursor: pointer;
  transition: background-color 300ms;

  .icon {
    margin-top: 13px;
  }

  &:hover {
    color: darken($primary-color, 10%);
  }

  &:active {
    background-color: darken(#E2F6FF, 5%);
  }
}

@media (max-width: 700px) {
  .visualization {
    &.is-hidden {
      display: none;
    }

    &.is-centered {
      opacity: 0.3;
    }
  }
  .visualization-toggle {
    &.is-visible {
      display: block;
    }
  }
}