.list {
  .list-header {
    position: sticky;
    top: -1px;
    background-color: $background-primary-color;
    color: $text-default-color;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    font-weight: bold;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    min-height: 40px;
    max-height: 40px;
    &.first {
      border-top: 0;
    }
  }

  .list-value {
    color: $text-default-color;
    margin-left: 15px;
    padding-left: 15px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    border-top: 1px solid $border-color;
    transition: background-color 100ms;
    background: #ffffff;
    min-height: 66px;
    max-height: 66px;

    &:hover {
      background: $background-color;
    }

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      padding: 2px 4px 2px 0;
    }

    .list-value-grow {
      flex-grow: 1;
      overflow: hidden;
      em {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .price {
        color: $primary-color;
      }
      .legend {
        font-size: 13px;
        color: $text-light-color;
      }
    }

    .list-value-icon {
      width: 38px;
      text-align: left;
    }

    .list-value-price {
      color: $primary-color;
      &.lowest {
        font-weight: bold;
      }
    }

    .list-value-airlines {
      min-width: 60px;
      text-align: right;
      overflow: hidden;
      white-space: nowrap;
      padding: 4px;
      .al {
        margin: 0 3px;
      }
    }

    .list-value-time {
      min-width: 110px;
      .icon {
        font-size: 9px;
        padding-right: 5px;
      }
    }

    .list-value-caret {
      color: $text-extralight-color;
      font-size: 12px;
      padding: 5px 15px;
    }
    .padleft {
      padding-left: 8px;
    }
    em {
      display: block;
      font-size: 13px;
      color: $text-light-color;
      font-style: normal;
      margin-top: 3px;
      span {
        padding-right: 5px;
      }
      &.weekday {
        padding-bottom: 4px;
      }
      .icon {
        font-size: 9px;
        padding-left: 2px;
      }
    }
  }

  .list-value-container {
    background-color: #ffffff;
    cursor: pointer;
    transition: background-color 100ms;
    &:hover {
      background-color: $background-color;
    }
    &.first {
      .list-value {
        border-top: 0;
      }
    }
  }

  .list-value-empty {
    background-color: #ffffff;
    text-align: center;
    padding: 23px 0;
    min-height: 66px;
    max-height: 66px;
    color: $text-extralight-color;
  }

  .list-no-result {
    background-color: #ffffff;
    text-align: center;
    padding: 23px 0;
    color: $text-extralight-color;
  }

}