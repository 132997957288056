.nav-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  color: $primary-color;
  font-size: 20px;
  display: none;
  z-index: 3;
  cursor: pointer;
  &:hover {
    color: darken($primary-color, 10%);
  }

  &.is-visible {
    z-index: 9999;
  }
}


.nav {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: rgba(#fff,0.90);
  border: 1px solid $border-color;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 0;
  z-index: 9998;

  &.is-visible {
    display: block;
  }

  .nav-header {
    height: 45px;
    width: 100%;
    display: none;
  
    .logo {
      margin: 10px 20px;
      display: flex;
      .text {
        margin-left: 10px;
        margin-top: 6px;
        .main {
          color: $primary-color;
          font-size: 22px;
        }
      }
      .img {
        background: transparent url('/static/images/logo.svg');
        width: 34px;
        height: 35px;
        background-size: 34px 35px;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      a, div {
        display: inline-block;
        padding: 0px 15px;
        margin: 5px 0;
        border-right: 1px solid $border-color;
        color: $primary-color;
        text-decoration: none;
        font-size: 12px;
        cursor: pointer;
        
        &:hover {
          text-decoration: underline;
        }
      }
      &:last-child {
        a, div {
          border-right: 0;
        }
      }
    }
  }
}

.external-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  
  .close {
    position: absolute;
    color: #fff;
    font-size: 20px;
    text-align: right;
    top: 20px;
    right: 20px;
    z-index: 3;
  }

  .backdrop {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .content {
    position: absolute;
    top: 50px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: #fff;
    border: 1px solid $border-color;
    box-shadow: 0 2px 42px 0 rgba(0, 0, 0, 0.05), 0 0 7px 0 rgba(0, 0, 0, 0.05);
    z-index: 2;
    border-radius: 6px;
    overflow: hidden;
  }

  iframe {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .modal-loading {
    position: absolute;
    height: 20px;
    margin-top: -20px;
    left: 0;
    width: 100%;
    text-align: center;
    top: 50%;
    font-size: 14px;
    color: $text-light-color;
    z-index: 1;
  }
}

@media (max-width: 700px) {
  .nav-selector {
    display: block;
  }

  .external-modal {
    .content {
      position: absolute;
      top: 50px;
      left: 5px;
      right: 10px;
      bottom: 5px;
    }
    .close {
      top: 20px;
      right: 20px;
    }
  }

  .nav {
    right: 0px;
    bottom: 0px;
    left: 0px;
    top: 0px;
    background: rgba(#fff,0.95);
    border: 0;
    box-shadow: none;
    border-radius: 0px;
    display: none;

    .nav-header {
      display: block;
    }
    ul {
      border-top: 1px solid $border-color;
      li {
        display: block;
        a, div {
          display: block;
          width: 100%;
          padding: 20px 15px;
          margin: 0 0 0 20px;
          border-bottom: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
          border-right: 0;
          color: $text-default-color;
          font-size: 16px;
        }
      }
    }
  }
}