@keyframes scale-down {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes darken-space {
  0% {
    background-color: #ffffff;
  }
  100% {
    background-color: #000000;
  }
}

.layout {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &.is-modal {
    animation: darken-space 20s ease-out;
    animation-fill-mode: forwards;
    .visualization{
      &.is-centered {
        opacity: 1;
      }
    }
    
    .map-svg {
      z-index: -1;
    }

    .map-tooltip {
      display: none;
    }

    .map-canvas {
      animation: scale-down 30s ease-out;
      animation-fill-mode: forwards;
      transform: scale(1);
    }
  }
}