@keyframes pulse-suggestions {
  0%{
    background-position: -200px 0
  }
  100%{
    background-position: 200px 0
  }
}

.home-content {
  position: absolute;
  width: 420px;
  height: 170px;
  left: 50%;
  top: 50%;
  margin-left: -210px;
  margin-top: -160px;
  padding: 10px;
  color: $text-default-color;
  font-size: 14px;
  line-height: 18px;
  z-index: 4;

  .logo {
    margin: 20px 65px 30px;
    display: flex;
    .text {
      margin-left: 20px;
      margin-top: 6px;
      .main {
        color: $primary-color;
        font-size: 28px;
        b {
          padding-left: 5px;
        }
      }
      .sub {
        color: $text-default-color;
        font-weight: normal;
        font-size: 15px;
        margin-top: 5px;
      }
    }
    .img {
      width: 53px;
      min-width: 53px;
      height: 57px;
      background: transparent url('/static/images/logo.svg');
      transition: transform 300ms;
    
      &:hover {
        transform: rotateY(360deg);
      }
    }
  }
  h1 {
    font-weight: bold;
    color: $primary-color;
    font-size: 24px;
    margin-top: 5px;
  }
  .suggestions {
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    color: $text-extralight-color;
    .suggestion-loading {
      display: inline-block;
      margin: 5px;
      padding: 5px 10px;
      border-radius: 3px;
      background: linear-gradient(to right, rgba(#cccccc, .5) 8%, rgba(#cccccc, .35) 18%, rgba(#cccccc, .5) 33%);
      background-size: 400px 20px;
      animation: pulse-suggestions 0.75s infinite ease-in-out;
      border: 1px solid #d0d0d0;
      color: #a9a9a9;
    }
    a {
      display: inline-block;
      margin: 5px;
      text-decoration: none;
      padding: 5px 10px;
      border-radius: 3px;
      color: darken($primary-color, 6%);
      background-color: rgba(lighten($primary-color, 30%), .5);
      border: 1px solid rgba($primary-color, .3);
      box-shadow: 0 1px 0 0 rgba($primary-color, .3);


      &:hover{
        background-color: rgba(lighten($primary-color, 40%), .6);
      }
    }
  }
  .home-footer {
    text-align: center;
    font-size: 15px;
    padding-top: 40px;
    a {
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 500px) {
  .home-content {
    left: 10px;
    right: 10px;
    margin-left: 0;
    width: auto;

    .logo {
      margin: 20px;
    }
  }
}