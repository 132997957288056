.al {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    -webkit-background-size: 544px 544px;
    -moz-background-size: 544px 544px;
    background-size: 544px 544px;
    background-repeat: no-repeat;
           
    @media screen and (-webkit-min-device-pixel-ratio: 1.0), screen and (min--moz-device-pixel-ratio: 1.0), screen and (-o-min-device-pixel-ratio: 100/100), screen and (min-device-pixel-ratio: 1.0), screen and (min-resolution: 1.0dppx) {
        background-image: url('/static/images/airlines.png');
    }

    @media screen and (-webkit-min-device-pixel-ratio: 2.0), screen and (min--moz-device-pixel-ratio: 2.0), screen and (-o-min-device-pixel-ratio: 200/100), screen and (min-device-pixel-ratio: 2.0), screen and (min-resolution: 2.0dppx) {
        background-image: url('/static/images/airlines2x.png');
    }

    @media screen and (-webkit-min-device-pixel-ratio: 4.0), screen and (min--moz-device-pixel-ratio: 4.0), screen and (-o-min-device-pixel-ratio: 400/100), screen and (min-device-pixel-ratio: 4.0), screen and (min-resolution: 4.0dppx) {
        background-image: url('/static/images/airlines4x.png');
    }

    &.large {
        background-image: url('/static/images/airlines2x.png');
        zoom: 1.5;
    }

    &.al-list {
        background-image: url('/static/images/airlines2x.png');
        zoom: 1.5;
    }
    
    &.header {
        background-image: url('/static/images/airlines2x.png');
        zoom: 1.4;
        vertical-align: -2px;
        margin-right: 3px;
    }

    &.is-preview {
        background-image: url('/static/images/airlines4x.png');
        zoom: 3.5;
    }

    &.al-ZY {
        background-position: 0 0;
    }

    &.al-ZX {
        background-position: -16px 0;
    }

    &.al-ZW {
        background-position: 0 -16px;
    }

    &.al-ZV {
        background-position: -16px -16px;
    }

    &.al-ZU {
        background-position: -32px 0;
    }

    &.al-ZT {
        background-position: -32px -16px;
    }

    &.al-ZS {
        background-position: 0 -32px;
    }

    &.al-ZR {
        background-position: -16px -32px;
    }

    &.al-ZQ {
        background-position: -32px -32px;
    }

    &.al-ZP {
        background-position: -48px 0;
    }

    &.al-ZO {
        background-position: -48px -16px;
    }

    &.al-ZN {
        background-position: -48px -32px;
    }

    &.al-ZM {
        background-position: 0 -48px;
    }

    &.al-ZL {
        background-position: -16px -48px;
    }

    &.al-ZK {
        background-position: -32px -48px;
    }

    &.al-ZJ {
        background-position: -48px -48px;
    }

    &.al-ZI {
        background-position: -64px 0;
    }

    &.al-ZH {
        background-position: -64px -16px;
    }

    &.al-ZG {
        background-position: -64px -32px;
    }

    &.al-ZF {
        background-position: -64px -48px;
    }

    &.al-ZE {
        background-position: 0 -64px;
    }

    &.al-ZD {
        background-position: -16px -64px;
    }

    &.al-ZC {
        background-position: -32px -64px;
    }

    &.al-ZB {
        background-position: -48px -64px;
    }

    &.al-ZA {
        background-position: -64px -64px;
    }

    &.al-Z9 {
        background-position: -80px 0;
    }

    &.al-Z8 {
        background-position: -80px -16px;
    }

    &.al-Z7 {
        background-position: -80px -32px;
    }

    &.al-Z6 {
        background-position: -80px -48px;
    }

    &.al-Z5 {
        background-position: -80px -64px;
    }

    &.al-Z4 {
        background-position: 0 -80px;
    }

    &.al-Z3 {
        background-position: -16px -80px;
    }

    &.al-Z2 {
        background-position: -32px -80px;
    }

    &.al-Z1 {
        background-position: -48px -80px;
    }

    &.al-YZ {
        background-position: -64px -80px;
    }

    &.al-YX {
        background-position: -80px -80px;
    }

    &.al-YW {
        background-position: -96px 0;
    }

    &.al-YV {
        background-position: -96px -16px;
    }

    &.al-YU {
        background-position: -96px -32px;
    }

    &.al-YT {
        background-position: -96px -48px;
    }

    &.al-YS {
        background-position: -96px -64px;
    }

    &.al-YR {
        background-position: -96px -80px;
    }

    &.al-YQ {
        background-position: 0 -96px;
    }

    &.al-YP {
        background-position: -16px -96px;
    }

    &.al-YO {
        background-position: -32px -96px;
    }

    &.al-YN {
        background-position: -48px -96px;
    }

    &.al-YM {
        background-position: -64px -96px;
    }

    &.al-YL {
        background-position: -80px -96px;
    }

    &.al-YK {
        background-position: -96px -96px;
    }

    &.al-YJ {
        background-position: -112px 0;
    }

    &.al-YI {
        background-position: -112px -16px;
    }

    &.al-YH {
        background-position: -112px -32px;
    }

    &.al-YG {
        background-position: -112px -48px;
    }

    &.al-YF {
        background-position: -112px -64px;
    }

    &.al-YE {
        background-position: -112px -80px;
    }

    &.al-YD {
        background-position: -112px -96px;
    }

    &.al-YC {
        background-position: 0 -112px;
    }

    &.al-YB {
        background-position: -16px -112px;
    }

    &.al-Y9 {
        background-position: -32px -112px;
    }

    &.al-Y8 {
        background-position: -48px -112px;
    }

    &.al-Y7 {
        background-position: -64px -112px;
    }

    &.al-Y6 {
        background-position: -80px -112px;
    }

    &.al-Y5 {
        background-position: -96px -112px;
    }

    &.al-Y4 {
        background-position: -112px -112px;
    }

    &.al-Y3 {
        background-position: -128px 0;
    }

    &.al-Y2 {
        background-position: -128px -16px;
    }

    &.al-Y1 {
        background-position: -128px -32px;
    }

    &.al-Y0 {
        background-position: -128px -48px;
    }

    &.al-XZ {
        background-position: -128px -64px;
    }

    &.al-XY {
        background-position: -128px -80px;
    }

    &.al-XX {
        background-position: -128px -96px;
    }

    &.al-XW {
        background-position: -128px -112px;
    }

    &.al-XV {
        background-position: 0 -128px;
    }

    &.al-XU {
        background-position: -16px -128px;
    }

    &.al-XT {
        background-position: -32px -128px;
    }

    &.al-XS {
        background-position: -48px -128px;
    }

    &.al-XR {
        background-position: -64px -128px;
    }

    &.al-XQ {
        background-position: -80px -128px;
    }

    &.al-XP {
        background-position: -96px -128px;
    }

    &.al-XO {
        background-position: -112px -128px;
    }

    &.al-XN {
        background-position: -128px -128px;
    }

    &.al-XM {
        background-position: -144px 0;
    }

    &.al-XL {
        background-position: -144px -16px;
    }

    &.al-XK {
        background-position: -144px -32px;
    }

    &.al-XJ {
        background-position: -144px -48px;
    }

    &.al-XI {
        background-position: -144px -64px;
    }

    &.al-XH {
        background-position: -144px -80px;
    }

    &.al-XG {
        background-position: -144px -96px;
    }

    &.al-XF {
        background-position: -144px -112px;
    }

    &.al-XE {
        background-position: -144px -128px;
    }

    &.al-XD {
        background-position: 0 -144px;
    }

    &.al-XC {
        background-position: -16px -144px;
    }

    &.al-XB {
        background-position: -32px -144px;
    }

    &.al-XA {
        background-position: -48px -144px;
    }

    &.al-X9 {
        background-position: -64px -144px;
    }

    &.al-X8 {
        background-position: -80px -144px;
    }

    &.al-X7 {
        background-position: -96px -144px;
    }

    &.al-X5 {
        background-position: -112px -144px;
    }

    &.al-X4 {
        background-position: -128px -144px;
    }

    &.al-X3 {
        background-position: -144px -144px;
    }

    &.al-X1 {
        background-position: -160px 0;
    }

    &.al-WZ {
        background-position: -160px -16px;
    }

    &.al-WY {
        background-position: -160px -32px;
    }

    &.al-WX {
        background-position: -160px -48px;
    }

    &.al-WW {
        background-position: -160px -64px;
    }

    &.al-WV {
        background-position: -160px -80px;
    }

    &.al-WU {
        background-position: -160px -96px;
    }

    &.al-WT {
        background-position: -160px -112px;
    }

    &.al-WS {
        background-position: -160px -128px;
    }

    &.al-WR {
        background-position: -160px -144px;
    }

    &.al-WQ {
        background-position: 0 -160px;
    }

    &.al-WP {
        background-position: -16px -160px;
    }

    &.al-WO {
        background-position: -32px -160px;
    }

    &.al-WN {
        background-position: -48px -160px;
    }

    &.al-WM {
        background-position: -64px -160px;
    }

    &.al-WL {
        background-position: -80px -160px;
    }

    &.al-WK {
        background-position: -96px -160px;
    }

    &.al-WJ {
        background-position: -112px -160px;
    }

    &.al-WI {
        background-position: -128px -160px;
    }

    &.al-WH {
        background-position: -144px -160px;
    }

    &.al-WG {
        background-position: -160px -160px;
    }

    &.al-WF {
        background-position: -176px 0;
    }

    &.al-WE {
        background-position: -176px -16px;
    }

    &.al-WD {
        background-position: -176px -32px;
    }

    &.al-WC {
        background-position: -176px -48px;
    }

    &.al-WB {
        background-position: -176px -64px;
    }

    &.al-WA {
        background-position: -176px -80px;
    }

    &.al-W9 {
        background-position: -176px -96px;
    }

    &.al-W8 {
        background-position: -176px -112px;
    }

    &.al-W7 {
        background-position: -176px -128px;
    }

    &.al-W6 {
        background-position: -176px -144px;
    }

    &.al-W5 {
        background-position: -176px -160px;
    }

    &.al-W4 {
        background-position: 0 -176px;
    }

    &.al-W3 {
        background-position: -16px -176px;
    }

    &.al-W2 {
        background-position: -32px -176px;
    }

    &.al-W1 {
        background-position: -48px -176px;
    }

    &.al-VZ {
        background-position: -64px -176px;
    }

    &.al-VY {
        background-position: -80px -176px;
    }

    &.al-VX {
        background-position: -96px -176px;
    }

    &.al-VW {
        background-position: -112px -176px;
    }

    &.al-VV {
        background-position: -128px -176px;
    }

    &.al-VU {
        background-position: -144px -176px;
    }

    &.al-VT {
        background-position: -160px -176px;
    }

    &.al-VS {
        background-position: -176px -176px;
    }

    &.al-VR {
        background-position: -192px 0;
    }

    &.al-VQ {
        background-position: -192px -16px;
    }

    &.al-VP {
        background-position: -192px -32px;
    }

    &.al-VO {
        background-position: -192px -48px;
    }

    &.al-VN {
        background-position: -192px -64px;
    }

    &.al-VM {
        background-position: -192px -80px;
    }

    &.al-VL {
        background-position: -192px -96px;
    }

    &.al-VK {
        background-position: -192px -112px;
    }

    &.al-VJ {
        background-position: -192px -128px;
    }

    &.al-VI {
        background-position: -192px -144px;
    }

    &.al-VH {
        background-position: -192px -160px;
    }

    &.al-VG {
        background-position: -192px -176px;
    }

    &.al-VF {
        background-position: 0 -192px;
    }

    &.al-VE {
        background-position: -16px -192px;
    }

    &.al-VD {
        background-position: -32px -192px;
    }

    &.al-VC {
        background-position: -48px -192px;
    }

    &.al-VB {
        background-position: -64px -192px;
    }

    &.al-VA {
        background-position: -80px -192px;
    }

    &.al-V9 {
        background-position: -96px -192px;
    }

    &.al-V8 {
        background-position: -112px -192px;
    }

    &.al-V7 {
        background-position: -128px -192px;
    }

    &.al-V6 {
        background-position: -144px -192px;
    }

    &.al-V5 {
        background-position: -160px -192px;
    }

    &.al-V4 {
        background-position: -176px -192px;
    }

    &.al-V3 {
        background-position: -192px -192px;
    }

    &.al-V2 {
        background-position: -208px 0;
    }

    &.al-V1 {
        background-position: -208px -16px;
    }

    &.al-V0 {
        background-position: -208px -32px;
    }

    &.al-UZ {
        background-position: -208px -48px;
    }

    &.al-UY {
        background-position: -208px -64px;
    }

    &.al-UX {
        background-position: -208px -80px;
    }

    &.al-UW {
        background-position: -208px -96px;
    }

    &.al-UV {
        background-position: -208px -112px;
    }

    &.al-UU {
        background-position: -208px -128px;
    }

    &.al-UT {
        background-position: -208px -144px;
    }

    &.al-US {
        background-position: -208px -160px;
    }

    &.al-UR {
        background-position: -208px -176px;
    }

    &.al-UQ {
        background-position: -208px -192px;
    }

    &.al-UP {
        background-position: 0 -208px;
    }

    &.al-UO {
        background-position: -16px -208px;
    }

    &.al-UN {
        background-position: -32px -208px;
    }

    &.al-UM {
        background-position: -48px -208px;
    }

    &.al-UL {
        background-position: -64px -208px;
    }

    &.al-UK {
        background-position: -80px -208px;
    }

    &.al-UJ {
        background-position: -96px -208px;
    }

    &.al-UI {
        background-position: -112px -208px;
    }

    &.al-UH {
        background-position: -128px -208px;
    }

    &.al-UG {
        background-position: -144px -208px;
    }

    &.al-UF {
        background-position: -160px -208px;
    }

    &.al-UE {
        background-position: -176px -208px;
    }

    &.al-UD {
        background-position: -192px -208px;
    }

    &.al-UC {
        background-position: -208px -208px;
    }

    &.al-UB {
        background-position: -224px 0;
    }

    &.al-UA {
        background-position: -224px -16px;
    }

    &.al-U9 {
        background-position: -224px -32px;
    }

    &.al-U8 {
        background-position: -224px -48px;
    }

    &.al-U7 {
        background-position: -224px -64px;
    }

    &.al-U6 {
        background-position: -224px -80px;
    }

    &.al-U5 {
        background-position: -224px -96px;
    }

    &.al-U4 {
        background-position: -224px -112px;
    }

    &.al-U3 {
        background-position: -224px -128px;
    }

    &.al-U2 {
        background-position: -224px -144px;
    }

    &.al-U1 {
        background-position: -224px -160px;
    }

    &.al-TZ {
        background-position: -224px -176px;
    }

    &.al-TY {
        background-position: -224px -192px;
    }

    &.al-TX {
        background-position: -224px -208px;
    }

    &.al-TW {
        background-position: 0 -224px;
    }

    &.al-TV {
        background-position: -16px -224px;
    }

    &.al-TU {
        background-position: -32px -224px;
    }

    &.al-TT {
        background-position: -48px -224px;
    }

    &.al-TS {
        background-position: -64px -224px;
    }

    &.al-TR {
        background-position: -80px -224px;
    }

    &.al-TQ {
        background-position: -96px -224px;
    }

    &.al-TP {
        background-position: -112px -224px;
    }

    &.al-TO {
        background-position: -128px -224px;
    }

    &.al-TN {
        background-position: -144px -224px;
    }

    &.al-TM {
        background-position: -160px -224px;
    }

    &.al-TL {
        background-position: -176px -224px;
    }

    &.al-TK {
        background-position: -192px -224px;
    }

    &.al-TJ {
        background-position: -208px -224px;
    }

    &.al-TI {
        background-position: -224px -224px;
    }

    &.al-TH {
        background-position: -240px 0;
    }

    &.al-TG {
        background-position: -240px -16px;
    }

    &.al-TF {
        background-position: -240px -32px;
    }

    &.al-TE {
        background-position: -240px -48px;
    }

    &.al-TD {
        background-position: -240px -64px;
    }

    &.al-TC {
        background-position: -240px -80px;
    }

    &.al-TB {
        background-position: -240px -96px;
    }

    &.al-TA {
        background-position: -240px -112px;
    }

    &.al-T9 {
        background-position: -240px -128px;
    }

    &.al-T8 {
        background-position: -240px -144px;
    }

    &.al-T7 {
        background-position: -240px -160px;
    }

    &.al-T6 {
        background-position: -240px -176px;
    }

    &.al-T5 {
        background-position: -240px -192px;
    }

    &.al-T4 {
        background-position: -240px -208px;
    }

    &.al-T3 {
        background-position: -240px -224px;
    }

    &.al-T2 {
        background-position: 0 -240px;
    }

    &.al-T1 {
        background-position: -16px -240px;
    }

    &.al-T0 {
        background-position: -32px -240px;
    }

    &.al-SZ {
        background-position: -48px -240px;
    }

    &.al-SY {
        background-position: -64px -240px;
    }

    &.al-SX {
        background-position: -80px -240px;
    }

    &.al-SW {
        background-position: -96px -240px;
    }

    &.al-SV {
        background-position: -112px -240px;
    }

    &.al-SU {
        background-position: -128px -240px;
    }

    &.al-ST {
        background-position: -144px -240px;
    }

    &.al-SS {
        background-position: -160px -240px;
    }

    &.al-SQ {
        background-position: -176px -240px;
    }

    &.al-SP {
        background-position: -192px -240px;
    }

    &.al-SO {
        background-position: -208px -240px;
    }

    &.al-SN {
        background-position: -224px -240px;
    }

    &.al-SM {
        background-position: -240px -240px;
    }

    &.al-SL {
        background-position: -256px 0;
    }

    &.al-SK {
        background-position: -256px -16px;
    }

    &.al-SJ {
        background-position: -256px -32px;
    }

    &.al-SI {
        background-position: -256px -48px;
    }

    &.al-SH {
        background-position: -256px -64px;
    }

    &.al-SG {
        background-position: -256px -80px;
    }

    &.al-SF {
        background-position: -256px -96px;
    }

    &.al-SE {
        background-position: -256px -112px;
    }

    &.al-SD {
        background-position: -256px -128px;
    }

    &.al-SC {
        background-position: -256px -144px;
    }

    &.al-SB {
        background-position: -256px -160px;
    }

    &.al-SA {
        background-position: -256px -176px;
    }

    &.al-S9 {
        background-position: -256px -192px;
    }

    &.al-S8 {
        background-position: -256px -208px;
    }

    &.al-S7 {
        background-position: -256px -224px;
    }

    &.al-S6 {
        background-position: -256px -240px;
    }

    &.al-S5 {
        background-position: 0 -256px;
    }

    &.al-S4 {
        background-position: -16px -256px;
    }

    &.al-S3 {
        background-position: -32px -256px;
    }

    &.al-S2 {
        background-position: -48px -256px;
    }

    &.al-S1 {
        background-position: -64px -256px;
    }

    &.al-RZ {
        background-position: -80px -256px;
    }

    &.al-RY {
        background-position: -96px -256px;
    }

    &.al-RX {
        background-position: -112px -256px;
    }

    &.al-RV {
        background-position: -128px -256px;
    }

    &.al-RU {
        background-position: -144px -256px;
    }

    &.al-RT {
        background-position: -160px -256px;
    }

    &.al-RS {
        background-position: -176px -256px;
    }

    &.al-RR {
        background-position: -192px -256px;
    }

    &.al-RQ {
        background-position: -208px -256px;
    }

    &.al-RP {
        background-position: -224px -256px;
    }

    &.al-RO {
        background-position: -240px -256px;
    }

    &.al-RN {
        background-position: -256px -256px;
    }

    &.al-RM {
        background-position: -272px 0;
    }

    &.al-RL {
        background-position: -272px -16px;
    }

    &.al-RK {
        background-position: -272px -32px;
    }

    &.al-RJ {
        background-position: -272px -48px;
    }

    &.al-RI {
        background-position: -272px -64px;
    }

    &.al-RH {
        background-position: -272px -80px;
    }

    &.al-RG {
        background-position: -272px -96px;
    }

    &.al-RF {
        background-position: -272px -112px;
    }

    &.al-RE {
        background-position: -272px -128px;
    }

    &.al-RD {
        background-position: -272px -144px;
    }

    &.al-RC {
        background-position: -272px -160px;
    }

    &.al-RB {
        background-position: -272px -176px;
    }

    &.al-RA {
        background-position: -272px -192px;
    }

    &.al-R9 {
        background-position: -272px -208px;
    }

    &.al-R8 {
        background-position: -272px -224px;
    }

    &.al-R7 {
        background-position: -272px -240px;
    }

    &.al-R6 {
        background-position: -272px -256px;
    }

    &.al-R5 {
        background-position: 0 -272px;
    }

    &.al-R4 {
        background-position: -16px -272px;
    }

    &.al-R3 {
        background-position: -32px -272px;
    }

    &.al-R2 {
        background-position: -48px -272px;
    }

    &.al-R1 {
        background-position: -64px -272px;
    }

    &.al-R0 {
        background-position: -80px -272px;
    }

    &.al-QZ {
        background-position: -96px -272px;
    }

    &.al-QY {
        background-position: -112px -272px;
    }

    &.al-QX {
        background-position: -128px -272px;
    }

    &.al-QW {
        background-position: -144px -272px;
    }

    &.al-QV {
        background-position: -160px -272px;
    }

    &.al-QU {
        background-position: -176px -272px;
    }

    &.al-QT {
        background-position: -192px -272px;
    }

    &.al-QS {
        background-position: -208px -272px;
    }

    &.al-QR {
        background-position: -224px -272px;
    }

    &.al-QQ {
        background-position: -240px -272px;
    }

    &.al-QP {
        background-position: -256px -272px;
    }

    &.al-QO {
        background-position: -272px -272px;
    }

    &.al-QN {
        background-position: -288px 0;
    }

    &.al-QM {
        background-position: -288px -16px;
    }

    &.al-QL {
        background-position: -288px -32px;
    }

    &.al-QK {
        background-position: -288px -48px;
    }

    &.al-QI {
        background-position: -288px -64px;
    }

    &.al-QH {
        background-position: -288px -80px;
    }

    &.al-QG {
        background-position: -288px -96px;
    }

    &.al-QF {
        background-position: -288px -112px;
    }

    &.al-QE {
        background-position: -288px -128px;
    }

    &.al-QD {
        background-position: -288px -144px;
    }

    &.al-QC {
        background-position: -288px -160px;
    }

    &.al-QB {
        background-position: -288px -176px;
    }

    &.al-QA {
        background-position: -288px -192px;
    }

    &.al-Q9 {
        background-position: -288px -208px;
    }

    &.al-Q8 {
        background-position: -288px -224px;
    }

    &.al-Q7 {
        background-position: -288px -240px;
    }

    &.al-Q6 {
        background-position: -288px -256px;
    }

    &.al-Q5 {
        background-position: -288px -272px;
    }

    &.al-Q4 {
        background-position: 0 -288px;
    }

    &.al-Q3 {
        background-position: -16px -288px;
    }

    &.al-Q2 {
        background-position: -32px -288px;
    }

    &.al-Q1 {
        background-position: -48px -288px;
    }

    &.al-Q0 {
        background-position: -64px -288px;
    }

    &.al-PZ {
        background-position: -80px -288px;
    }

    &.al-PY {
        background-position: -96px -288px;
    }

    &.al-PX {
        background-position: -112px -288px;
    }

    &.al-PW {
        background-position: -128px -288px;
    }

    &.al-PV {
        background-position: -144px -288px;
    }

    &.al-PU {
        background-position: -160px -288px;
    }

    &.al-PT {
        background-position: -176px -288px;
    }

    &.al-PS {
        background-position: -192px -288px;
    }

    &.al-PR {
        background-position: -208px -288px;
    }

    &.al-PQ {
        background-position: -224px -288px;
    }

    &.al-PP {
        background-position: -240px -288px;
    }

    &.al-PO {
        background-position: -256px -288px;
    }

    &.al-PN {
        background-position: -272px -288px;
    }

    &.al-PM {
        background-position: -288px -288px;
    }

    &.al-PL {
        background-position: -304px 0;
    }

    &.al-PK {
        background-position: -304px -16px;
    }

    &.al-PJ {
        background-position: -304px -32px;
    }

    &.al-PI {
        background-position: -304px -48px;
    }

    &.al-PH {
        background-position: -304px -64px;
    }

    &.al-PG {
        background-position: -304px -80px;
    }

    &.al-PF {
        background-position: -304px -96px;
    }

    &.al-PE {
        background-position: -304px -112px;
    }

    &.al-PD {
        background-position: -304px -128px;
    }

    &.al-PC {
        background-position: -304px -144px;
    }

    &.al-PB {
        background-position: -304px -160px;
    }

    &.al-PA {
        background-position: -304px -176px;
    }

    &.al-P9 {
        background-position: -304px -192px;
    }

    &.al-P8 {
        background-position: -304px -208px;
    }

    &.al-P7 {
        background-position: -304px -224px;
    }

    &.al-P6 {
        background-position: -304px -240px;
    }

    &.al-P5 {
        background-position: -304px -256px;
    }

    &.al-P4 {
        background-position: -304px -272px;
    }

    &.al-P3 {
        background-position: -304px -288px;
    }

    &.al-P2 {
        background-position: 0 -304px;
    }

    &.al-P1 {
        background-position: -16px -304px;
    }

    &.al-P0 {
        background-position: -32px -304px;
    }

    &.al-OZ {
        background-position: -48px -304px;
    }

    &.al-OY {
        background-position: -64px -304px;
    }

    &.al-OX {
        background-position: -80px -304px;
    }

    &.al-OW {
        background-position: -96px -304px;
    }

    &.al-OV {
        background-position: -112px -304px;
    }

    &.al-OU {
        background-position: -128px -304px;
    }

    &.al-OT {
        background-position: -144px -304px;
    }

    &.al-OS {
        background-position: -160px -304px;
    }

    &.al-OR {
        background-position: -176px -304px;
    }

    &.al-OQ {
        background-position: -192px -304px;
    }

    &.al-OP {
        background-position: -208px -304px;
    }

    &.al-OO {
        background-position: -224px -304px;
    }

    &.al-ON {
        background-position: -240px -304px;
    }

    &.al-OM {
        background-position: -256px -304px;
    }

    &.al-OL {
        background-position: -272px -304px;
    }

    &.al-OK {
        background-position: -288px -304px;
    }

    &.al-OJ {
        background-position: -304px -304px;
    }

    &.al-OI {
        background-position: -320px 0;
    }

    &.al-OH {
        background-position: -320px -16px;
    }

    &.al-OG {
        background-position: -320px -32px;
    }

    &.al-OF {
        background-position: -320px -48px;
    }

    &.al-OE {
        background-position: -320px -64px;
    }

    &.al-OD {
        background-position: -320px -80px;
    }

    &.al-OC {
        background-position: -320px -96px;
    }

    &.al-OB {
        background-position: -320px -112px;
    }

    &.al-OA {
        background-position: -320px -128px;
    }

    &.al-O9 {
        background-position: -320px -144px;
    }

    &.al-O8 {
        background-position: -320px -160px;
    }

    &.al-O7 {
        background-position: -320px -176px;
    }

    &.al-O6 {
        background-position: -320px -192px;
    }

    &.al-O5 {
        background-position: -320px -208px;
    }

    &.al-O4 {
        background-position: -320px -224px;
    }

    &.al-O3 {
        background-position: -320px -240px;
    }

    &.al-O2 {
        background-position: -320px -256px;
    }

    &.al-NZ {
        background-position: -320px -272px;
    }

    &.al-NY {
        background-position: -320px -288px;
    }

    &.al-NX {
        background-position: -320px -304px;
    }

    &.al-NW {
        background-position: 0 -320px;
    }

    &.al-NV {
        background-position: -16px -320px;
    }

    &.al-NU {
        background-position: -32px -320px;
    }

    &.al-NT {
        background-position: -48px -320px;
    }

    &.al-NS {
        background-position: -64px -320px;
    }

    &.al-NR {
        background-position: -80px -320px;
    }

    &.al-NQ {
        background-position: -96px -320px;
    }

    &.al-NP {
        background-position: -112px -320px;
    }

    &.al-NO {
        background-position: -128px -320px;
    }

    &.al-NN {
        background-position: -144px -320px;
    }

    &.al-NM {
        background-position: -160px -320px;
    }

    &.al-NL {
        background-position: -176px -320px;
    }

    &.al-NK {
        background-position: -192px -320px;
    }

    &.al-NJ {
        background-position: -208px -320px;
    }

    &.al-NI {
        background-position: -224px -320px;
    }

    &.al-NH {
        background-position: -240px -320px;
    }

    &.al-NG {
        background-position: -256px -320px;
    }

    &.al-NF {
        background-position: -272px -320px;
    }

    &.al-NE {
        background-position: -288px -320px;
    }

    &.al-ND {
        background-position: -304px -320px;
    }

    &.al-NC {
        background-position: -320px -320px;
    }

    &.al-NB {
        background-position: -336px 0;
    }

    &.al-N9 {
        background-position: -336px -16px;
    }

    &.al-N8 {
        background-position: -336px -32px;
    }

    &.al-N7 {
        background-position: -336px -48px;
    }

    &.al-N6 {
        background-position: -336px -64px;
    }

    &.al-N5 {
        background-position: -336px -80px;
    }

    &.al-N4 {
        background-position: -336px -96px;
    }

    &.al-N3 {
        background-position: -336px -112px;
    }

    &.al-N2 {
        background-position: -336px -128px;
    }

    &.al-N1 {
        background-position: -336px -144px;
    }

    &.al-MZ {
        background-position: -336px -160px;
    }

    &.al-MY {
        background-position: -336px -176px;
    }

    &.al-MX {
        background-position: -336px -192px;
    }

    &.al-MW {
        background-position: -336px -208px;
    }

    &.al-MV {
        background-position: -336px -224px;
    }

    &.al-MU {
        background-position: -336px -240px;
    }

    &.al-MT {
        background-position: -336px -256px;
    }

    &.al-MS {
        background-position: -336px -272px;
    }

    &.al-MR {
        background-position: -336px -288px;
    }

    &.al-MQ {
        background-position: -336px -304px;
    }

    &.al-MP {
        background-position: -336px -320px;
    }

    &.al-MO {
        background-position: 0 -336px;
    }

    &.al-MN {
        background-position: -16px -336px;
    }

    &.al-MM {
        background-position: -32px -336px;
    }

    &.al-ML {
        background-position: -48px -336px;
    }

    &.al-MK {
        background-position: -64px -336px;
    }

    &.al-MJ {
        background-position: -80px -336px;
    }

    &.al-MI {
        background-position: -96px -336px;
    }

    &.al-MH {
        background-position: -112px -336px;
    }

    &.al-MG {
        background-position: -128px -336px;
    }

    &.al-MF {
        background-position: -144px -336px;
    }

    &.al-ME {
        background-position: -160px -336px;
    }

    &.al-MD {
        background-position: -176px -336px;
    }

    &.al-MC {
        background-position: -192px -336px;
    }

    &.al-MB {
        background-position: -208px -336px;
    }

    &.al-MA {
        background-position: -224px -336px;
    }

    &.al-M9 {
        background-position: -240px -336px;
    }

    &.al-M8 {
        background-position: -256px -336px;
    }

    &.al-M7 {
        background-position: -272px -336px;
    }

    &.al-M6 {
        background-position: -288px -336px;
    }

    &.al-M5 {
        background-position: -304px -336px;
    }

    &.al-M4 {
        background-position: -320px -336px;
    }

    &.al-M3 {
        background-position: -336px -336px;
    }

    &.al-M2 {
        background-position: -352px 0;
    }

    &.al-M1 {
        background-position: -352px -16px;
    }

    &.al-M0 {
        background-position: -352px -32px;
    }

    &.al-LZ {
        background-position: -352px -48px;
    }

    &.al-LY {
        background-position: -352px -64px;
    }

    &.al-LX {
        background-position: -352px -80px;
    }

    &.al-LW {
        background-position: -352px -96px;
    }

    &.al-LV {
        background-position: -352px -112px;
    }

    &.al-LU {
        background-position: -352px -128px;
    }

    &.al-LT {
        background-position: -352px -144px;
    }

    &.al-LS {
        background-position: -352px -160px;
    }

    &.al-LR {
        background-position: -352px -176px;
    }

    &.al-LQ {
        background-position: -352px -192px;
    }

    &.al-LP {
        background-position: -352px -208px;
    }

    &.al-LO {
        background-position: -352px -224px;
    }

    &.al-LN {
        background-position: -352px -240px;
    }

    &.al-LM {
        background-position: -352px -256px;
    }

    &.al-LL {
        background-position: -352px -272px;
    }

    &.al-LK {
        background-position: -352px -288px;
    }

    &.al-LJ {
        background-position: -352px -304px;
    }

    &.al-LI {
        background-position: -352px -320px;
    }

    &.al-LH {
        background-position: -352px -336px;
    }

    &.al-LG {
        background-position: 0 -352px;
    }

    &.al-LF {
        background-position: -16px -352px;
    }

    &.al-LE {
        background-position: -32px -352px;
    }

    &.al-LD {
        background-position: -48px -352px;
    }

    &.al-LC {
        background-position: -64px -352px;
    }

    &.al-LB {
        background-position: -80px -352px;
    }

    &.al-LA {
        background-position: -96px -352px;
    }

    &.al-L9 {
        background-position: -112px -352px;
    }

    &.al-L8 {
        background-position: -128px -352px;
    }

    &.al-L7 {
        background-position: -144px -352px;
    }

    &.al-L6 {
        background-position: -160px -352px;
    }

    &.al-L5 {
        background-position: -176px -352px;
    }

    &.al-L4 {
        background-position: -192px -352px;
    }

    &.al-L3 {
        background-position: -208px -352px;
    }

    &.al-L2 {
        background-position: -224px -352px;
    }

    &.al-L1 {
        background-position: -240px -352px;
    }

    &.al-KZ {
        background-position: -256px -352px;
    }

    &.al-KY {
        background-position: -272px -352px;
    }

    &.al-KX {
        background-position: -288px -352px;
    }

    &.al-KW {
        background-position: -304px -352px;
    }

    &.al-KV {
        background-position: -320px -352px;
    }

    &.al-KU {
        background-position: -336px -352px;
    }

    &.al-KT {
        background-position: -352px -352px;
    }

    &.al-KS {
        background-position: -368px 0;
    }

    &.al-KR {
        background-position: -368px -16px;
    }

    &.al-KQ {
        background-position: -368px -32px;
    }

    &.al-KP {
        background-position: -368px -48px;
    }

    &.al-KO {
        background-position: -368px -64px;
    }

    &.al-KN {
        background-position: -368px -80px;
    }

    &.al-KM {
        background-position: -368px -96px;
    }

    &.al-KL {
        background-position: -368px -112px;
    }

    &.al-KK {
        background-position: -368px -128px;
    }

    &.al-KJ {
        background-position: -368px -144px;
    }

    &.al-KI {
        background-position: -368px -160px;
    }

    &.al-KH {
        background-position: -368px -176px;
    }

    &.al-KG {
        background-position: -368px -192px;
    }

    &.al-KF {
        background-position: -368px -208px;
    }

    &.al-KE {
        background-position: -368px -224px;
    }

    &.al-KD {
        background-position: -368px -240px;
    }

    &.al-KC {
        background-position: -368px -256px;
    }

    &.al-KB {
        background-position: -368px -272px;
    }

    &.al-KA {
        background-position: -368px -288px;
    }

    &.al-K9 {
        background-position: -368px -304px;
    }

    &.al-K8 {
        background-position: -368px -320px;
    }

    &.al-K7 {
        background-position: -368px -336px;
    }

    &.al-K6 {
        background-position: -368px -352px;
    }

    &.al-K5 {
        background-position: 0 -368px;
    }

    &.al-K4 {
        background-position: -16px -368px;
    }

    &.al-K3 {
        background-position: -32px -368px;
    }

    &.al-K2 {
        background-position: -48px -368px;
    }

    &.al-K1 {
        background-position: -64px -368px;
    }

    &.al-JZ {
        background-position: -80px -368px;
    }

    &.al-JY {
        background-position: -96px -368px;
    }

    &.al-JX {
        background-position: -112px -368px;
    }

    &.al-JW {
        background-position: -128px -368px;
    }

    &.al-JV {
        background-position: -144px -368px;
    }

    &.al-JU {
        background-position: -160px -368px;
    }

    &.al-JT {
        background-position: -176px -368px;
    }

    &.al-JS {
        background-position: -192px -368px;
    }

    &.al-JR {
        background-position: -208px -368px;
    }

    &.al-JQ {
        background-position: -224px -368px;
    }

    &.al-JP {
        background-position: -240px -368px;
    }

    &.al-JO {
        background-position: -256px -368px;
    }

    &.al-JN {
        background-position: -272px -368px;
    }

    &.al-JM {
        background-position: -288px -368px;
    }

    &.al-JL {
        background-position: -304px -368px;
    }

    &.al-JK {
        background-position: -320px -368px;
    }

    &.al-JJ {
        background-position: -336px -368px;
    }

    &.al-JI {
        background-position: -352px -368px;
    }

    &.al-JH {
        background-position: -368px -368px;
    }

    &.al-JG {
        background-position: -384px 0;
    }

    &.al-JF {
        background-position: -384px -16px;
    }

    &.al-JE {
        background-position: -384px -32px;
    }

    &.al-JD {
        background-position: -384px -48px;
    }

    &.al-JC {
        background-position: -384px -64px;
    }

    &.al-JB {
        background-position: -384px -80px;
    }

    &.al-JA {
        background-position: -384px -96px;
    }

    &.al-J9 {
        background-position: -384px -112px;
    }

    &.al-J8 {
        background-position: -384px -128px;
    }

    &.al-J7 {
        background-position: -384px -144px;
    }

    &.al-J6 {
        background-position: -384px -160px;
    }

    &.al-J5 {
        background-position: -384px -176px;
    }

    &.al-J4 {
        background-position: -384px -192px;
    }

    &.al-J3 {
        background-position: -384px -208px;
    }

    &.al-J2 {
        background-position: -384px -224px;
    }

    &.al-J1 {
        background-position: -384px -240px;
    }

    &.al-J0 {
        background-position: -384px -256px;
    }

    &.al-IZ {
        background-position: -384px -272px;
    }

    &.al-IY {
        background-position: -384px -288px;
    }

    &.al-IX {
        background-position: -384px -304px;
    }

    &.al-IW {
        background-position: -384px -320px;
    }

    &.al-IV {
        background-position: -384px -336px;
    }

    &.al-IU {
        background-position: -384px -352px;
    }

    &.al-IT {
        background-position: -384px -368px;
    }

    &.al-IS {
        background-position: 0 -384px;
    }

    &.al-IR {
        background-position: -16px -384px;
    }

    &.al-IQ {
        background-position: -32px -384px;
    }

    &.al-IP {
        background-position: -48px -384px;
    }

    &.al-IO {
        background-position: -64px -384px;
    }

    &.al-IN {
        background-position: -80px -384px;
    }

    &.al-IM {
        background-position: -96px -384px;
    }

    &.al-IL {
        background-position: -112px -384px;
    }

    &.al-IK {
        background-position: -128px -384px;
    }

    &.al-IJ {
        background-position: -144px -384px;
    }

    &.al-II {
        background-position: -160px -384px;
    }

    &.al-IH {
        background-position: -176px -384px;
    }

    &.al-IG {
        background-position: -192px -384px;
    }

    &.al-IF {
        background-position: -208px -384px;
    }

    &.al-IE {
        background-position: -224px -384px;
    }

    &.al-IC {
        background-position: -240px -384px;
    }

    &.al-IB {
        background-position: -256px -384px;
    }

    &.al-IA {
        background-position: -272px -384px;
    }

    &.al-I8 {
        background-position: -288px -384px;
    }

    &.al-I7 {
        background-position: -304px -384px;
    }

    &.al-I6 {
        background-position: -320px -384px;
    }

    &.al-I5 {
        background-position: -336px -384px;
    }

    &.al-I4 {
        background-position: -352px -384px;
    }

    &.al-I3 {
        background-position: -368px -384px;
    }

    &.al-I2 {
        background-position: -384px -384px;
    }

    &.al-HZ {
        background-position: -400px 0;
    }

    &.al-HY {
        background-position: -400px -16px;
    }

    &.al-HX {
        background-position: -400px -32px;
    }

    &.al-HW {
        background-position: -400px -48px;
    }

    &.al-HV {
        background-position: -400px -64px;
    }

    &.al-HU {
        background-position: -400px -80px;
    }

    &.al-HT {
        background-position: -400px -96px;
    }

    &.al-HS {
        background-position: -400px -112px;
    }

    &.al-HR {
        background-position: -400px -128px;
    }

    &.al-HQ {
        background-position: -400px -144px;
    }

    &.al-HP {
        background-position: -400px -160px;
    }

    &.al-HO {
        background-position: -400px -176px;
    }

    &.al-HN {
        background-position: -400px -192px;
    }

    &.al-HM {
        background-position: -400px -208px;
    }

    &.al-HL {
        background-position: -400px -224px;
    }

    &.al-HK {
        background-position: -400px -240px;
    }

    &.al-HJ {
        background-position: -400px -256px;
    }

    &.al-HI {
        background-position: -400px -272px;
    }

    &.al-HH {
        background-position: -400px -288px;
    }

    &.al-HG {
        background-position: -400px -304px;
    }

    &.al-HF {
        background-position: -400px -320px;
    }

    &.al-HE {
        background-position: -400px -336px;
    }

    &.al-HD {
        background-position: -400px -352px;
    }

    &.al-HC {
        background-position: -400px -368px;
    }

    &.al-HB {
        background-position: -400px -384px;
    }

    &.al-HA {
        background-position: 0 -400px;
    }

    &.al-H9 {
        background-position: -16px -400px;
    }

    &.al-H8 {
        background-position: -32px -400px;
    }

    &.al-H7 {
        background-position: -48px -400px;
    }

    &.al-H6 {
        background-position: -64px -400px;
    }

    &.al-H5 {
        background-position: -80px -400px;
    }

    &.al-H4 {
        background-position: -96px -400px;
    }

    &.al-H3 {
        background-position: -112px -400px;
    }

    &.al-H2 {
        background-position: -128px -400px;
    }

    &.al-H1 {
        background-position: -144px -400px;
    }

    &.al-GZ {
        background-position: -160px -400px;
    }

    &.al-GY {
        background-position: -176px -400px;
    }

    &.al-GX {
        background-position: -192px -400px;
    }

    &.al-GW {
        background-position: -208px -400px;
    }

    &.al-GV {
        background-position: -224px -400px;
    }

    &.al-GU {
        background-position: -240px -400px;
    }

    &.al-GT {
        background-position: -256px -400px;
    }

    &.al-GS {
        background-position: -272px -400px;
    }

    &.al-GR {
        background-position: -288px -400px;
    }

    &.al-GQ {
        background-position: -304px -400px;
    }

    &.al-GP {
        background-position: -320px -400px;
    }

    &.al-GO {
        background-position: -336px -400px;
    }

    &.al-GN {
        background-position: -352px -400px;
    }

    &.al-GM {
        background-position: -368px -400px;
    }

    &.al-GL {
        background-position: -384px -400px;
    }

    &.al-GK {
        background-position: -400px -400px;
    }

    &.al-GJ {
        background-position: -416px 0;
    }

    &.al-GI {
        background-position: -416px -16px;
    }

    &.al-GH {
        background-position: -416px -32px;
    }

    &.al-GG {
        background-position: -416px -48px;
    }

    &.al-GF {
        background-position: -416px -64px;
    }

    &.al-GE {
        background-position: -416px -80px;
    }

    &.al-GD {
        background-position: -416px -96px;
    }

    &.al-GC {
        background-position: -416px -112px;
    }

    &.al-GB {
        background-position: -416px -128px;
    }

    &.al-GA {
        background-position: -416px -144px;
    }

    &.al-G9 {
        background-position: -416px -160px;
    }

    &.al-G8 {
        background-position: -416px -176px;
    }

    &.al-G7 {
        background-position: -416px -192px;
    }

    &.al-G5 {
        background-position: -416px -208px;
    }

    &.al-G4 {
        background-position: -416px -224px;
    }

    &.al-G3 {
        background-position: -416px -240px;
    }

    &.al-G2 {
        background-position: -416px -256px;
    }

    &.al-G1 {
        background-position: -416px -272px;
    }

    &.al-G0 {
        background-position: -416px -288px;
    }

    &.al-FZ {
        background-position: -416px -304px;
    }

    &.al-FY {
        background-position: -416px -320px;
    }

    &.al-FX {
        background-position: -416px -336px;
    }

    &.al-FW {
        background-position: -416px -352px;
    }

    &.al-FV {
        background-position: -416px -368px;
    }

    &.al-FU {
        background-position: -416px -384px;
    }

    &.al-FT {
        background-position: -416px -400px;
    }

    &.al-FS {
        background-position: 0 -416px;
    }

    &.al-FR {
        background-position: -16px -416px;
    }

    &.al-FP {
        background-position: -32px -416px;
    }

    &.al-FO {
        background-position: -48px -416px;
    }

    &.al-FN {
        background-position: -64px -416px;
    }

    &.al-FM {
        background-position: -80px -416px;
    }

    &.al-FL {
        background-position: -96px -416px;
    }

    &.al-FK {
        background-position: -112px -416px;
    }

    &.al-FJ {
        background-position: -128px -416px;
    }

    &.al-FI {
        background-position: -144px -416px;
    }

    &.al-FH {
        background-position: -160px -416px;
    }

    &.al-FG {
        background-position: -176px -416px;
    }

    &.al-FF {
        background-position: -192px -416px;
    }

    &.al-FE {
        background-position: -208px -416px;
    }

    &.al-FD {
        background-position: -224px -416px;
    }

    &.al-FC {
        background-position: -240px -416px;
    }

    &.al-FB {
        background-position: -256px -416px;
    }

    &.al-FA {
        background-position: -272px -416px;
    }

    &.al-F9 {
        background-position: -288px -416px;
    }

    &.al-F8 {
        background-position: -304px -416px;
    }

    &.al-F7 {
        background-position: -320px -416px;
    }

    &.al-F6 {
        background-position: -336px -416px;
    }

    &.al-F5 {
        background-position: -352px -416px;
    }

    &.al-F4 {
        background-position: -368px -416px;
    }

    &.al-F3 {
        background-position: -384px -416px;
    }

    &.al-F2 {
        background-position: -400px -416px;
    }

    &.al-F1 {
        background-position: -416px -416px;
    }

    &.al-F0 {
        background-position: -432px 0;
    }

    &.al-EZ {
        background-position: -432px -16px;
    }

    &.al-EY {
        background-position: -432px -32px;
    }

    &.al-EX {
        background-position: -432px -48px;
    }

    &.al-EW {
        background-position: -432px -64px;
    }

    &.al-EV {
        background-position: -432px -80px;
    }

    &.al-EU {
        background-position: -432px -96px;
    }

    &.al-ET {
        background-position: -432px -112px;
    }

    &.al-ES {
        background-position: -432px -128px;
    }

    &.al-ER {
        background-position: -432px -144px;
    }

    &.al-EQ {
        background-position: -432px -160px;
    }

    &.al-EP {
        background-position: -432px -176px;
    }

    &.al-EO {
        background-position: -432px -192px;
    }

    &.al-EN {
        background-position: -432px -208px;
    }

    &.al-EM {
        background-position: -432px -224px;
    }

    &.al-EL {
        background-position: -432px -240px;
    }

    &.al-EK {
        background-position: -432px -256px;
    }

    &.al-EJ {
        background-position: -432px -272px;
    }

    &.al-EI {
        background-position: -432px -288px;
    }

    &.al-EH {
        background-position: -432px -304px;
    }

    &.al-EG {
        background-position: -432px -320px;
    }

    &.al-EF {
        background-position: -432px -336px;
    }

    &.al-EE {
        background-position: -432px -352px;
    }

    &.al-ED {
        background-position: -432px -368px;
    }

    &.al-EC {
        background-position: -432px -384px;
    }

    &.al-EB {
        background-position: -432px -400px;
    }

    &.al-EA {
        background-position: -432px -416px;
    }

    &.al-E9 {
        background-position: 0 -432px;
    }

    &.al-E8 {
        background-position: -16px -432px;
    }

    &.al-E7 {
        background-position: -32px -432px;
    }

    &.al-E6 {
        background-position: -48px -432px;
    }

    &.al-E5 {
        background-position: -64px -432px;
    }

    &.al-E4 {
        background-position: -80px -432px;
    }

    &.al-E3 {
        background-position: -96px -432px;
    }

    &.al-E2 {
        background-position: -112px -432px;
    }

    &.al-E1 {
        background-position: -128px -432px;
    }

    &.al-DZ {
        background-position: -144px -432px;
    }

    &.al-DY {
        background-position: -160px -432px;
    }

    &.al-DX {
        background-position: -176px -432px;
    }

    &.al-DW {
        background-position: -192px -432px;
    }

    &.al-DV {
        background-position: -208px -432px;
    }

    &.al-DU {
        background-position: -224px -432px;
    }

    &.al-DT {
        background-position: -240px -432px;
    }

    &.al-DS {
        background-position: -256px -432px;
    }

    &.al-DR {
        background-position: -272px -432px;
    }

    &.al-DQ {
        background-position: -288px -432px;
    }

    &.al-DP {
        background-position: -304px -432px;
    }

    &.al-DO {
        background-position: -320px -432px;
    }

    &.al-DN {
        background-position: -336px -432px;
    }

    &.al-DM {
        background-position: -352px -432px;
    }

    &.al-DL {
        background-position: -368px -432px;
    }

    &.al-DK {
        background-position: -384px -432px;
    }

    &.al-DJ {
        background-position: -400px -432px;
    }

    &.al-DI {
        background-position: -416px -432px;
    }

    &.al-DH {
        background-position: -432px -432px;
    }

    &.al-DG {
        background-position: -448px 0;
    }

    &.al-DF {
        background-position: -448px -16px;
    }

    &.al-DE {
        background-position: -448px -32px;
    }

    &.al-DD {
        background-position: -448px -48px;
    }

    &.al-DC {
        background-position: -448px -64px;
    }

    &.al-DB {
        background-position: -448px -80px;
    }

    &.al-DA {
        background-position: -448px -96px;
    }

    &.al-D9 {
        background-position: -448px -112px;
    }

    &.al-D8 {
        background-position: -448px -128px;
    }

    &.al-D7 {
        background-position: -448px -144px;
    }

    &.al-D6 {
        background-position: -448px -160px;
    }

    &.al-D5 {
        background-position: -448px -176px;
    }

    &.al-D4 {
        background-position: -448px -192px;
    }

    &.al-D3 {
        background-position: -448px -208px;
    }

    &.al-D2 {
        background-position: -448px -224px;
    }

    &.al-D1 {
        background-position: -448px -240px;
    }

    &.al-D0 {
        background-position: -448px -256px;
    }

    &.al-CZ {
        background-position: -448px -272px;
    }

    &.al-CY {
        background-position: -448px -288px;
    }

    &.al-CX {
        background-position: -448px -304px;
    }

    &.al-CW {
        background-position: -448px -320px;
    }

    &.al-CV {
        background-position: -448px -336px;
    }

    &.al-CU {
        background-position: -448px -352px;
    }

    &.al-CT {
        background-position: -448px -368px;
    }

    &.al-CS {
        background-position: -448px -384px;
    }

    &.al-CR {
        background-position: -448px -400px;
    }

    &.al-CQ {
        background-position: -448px -416px;
    }

    &.al-CP {
        background-position: -448px -432px;
    }

    &.al-CO {
        background-position: 0 -448px;
    }

    &.al-CN {
        background-position: -16px -448px;
    }

    &.al-CM {
        background-position: -32px -448px;
    }

    &.al-CL {
        background-position: -48px -448px;
    }

    &.al-CK {
        background-position: -64px -448px;
    }

    &.al-CJ {
        background-position: -80px -448px;
    }

    &.al-CI {
        background-position: -96px -448px;
    }

    &.al-CH {
        background-position: -112px -448px;
    }

    &.al-CG {
        background-position: -128px -448px;
    }

    &.al-CF {
        background-position: -144px -448px;
    }

    &.al-CE {
        background-position: -160px -448px;
    }

    &.al-CD {
        background-position: -176px -448px;
    }

    &.al-CC {
        background-position: -192px -448px;
    }

    &.al-CB {
        background-position: -208px -448px;
    }

    &.al-CA {
        background-position: -224px -448px;
    }

    &.al-C9 {
        background-position: -240px -448px;
    }

    &.al-C8 {
        background-position: -256px -448px;
    }

    &.al-C7 {
        background-position: -272px -448px;
    }

    &.al-C6 {
        background-position: -288px -448px;
    }

    &.al-C5 {
        background-position: -304px -448px;
    }

    &.al-C4 {
        background-position: -320px -448px;
    }

    &.al-C3 {
        background-position: -336px -448px;
    }

    &.al-C2 {
        background-position: -352px -448px;
    }

    &.al-C1 {
        background-position: -368px -448px;
    }

    &.al-BZ {
        background-position: -384px -448px;
    }

    &.al-BY {
        background-position: -400px -448px;
    }

    &.al-BX {
        background-position: -416px -448px;
    }

    &.al-BW {
        background-position: -432px -448px;
    }

    &.al-BV {
        background-position: -448px -448px;
    }

    &.al-BU {
        background-position: -464px 0;
    }

    &.al-BT {
        background-position: -464px -16px;
    }

    &.al-BS {
        background-position: -464px -32px;
    }

    &.al-BR {
        background-position: -464px -48px;
    }

    &.al-BQ {
        background-position: -464px -64px;
    }

    &.al-BP {
        background-position: -464px -80px;
    }

    &.al-BO {
        background-position: -464px -96px;
    }

    &.al-BN {
        background-position: -464px -112px;
    }

    &.al-BM {
        background-position: -464px -128px;
    }

    &.al-BL {
        background-position: -464px -144px;
    }

    &.al-BK {
        background-position: -464px -160px;
    }

    &.al-BJ {
        background-position: -464px -176px;
    }

    &.al-BI {
        background-position: -464px -192px;
    }

    &.al-BH {
        background-position: -464px -208px;
    }

    &.al-BG {
        background-position: -464px -224px;
    }

    &.al-BF {
        background-position: -464px -240px;
    }

    &.al-BE {
        background-position: -464px -256px;
    }

    &.al-BD {
        background-position: -464px -272px;
    }

    &.al-BC {
        background-position: -464px -288px;
    }

    &.al-BB {
        background-position: -464px -304px;
    }

    &.al-BA {
        background-position: -464px -320px;
    }

    &.al-B9 {
        background-position: -464px -336px;
    }

    &.al-B8 {
        background-position: -464px -352px;
    }

    &.al-B7 {
        background-position: -464px -368px;
    }

    &.al-B6 {
        background-position: -464px -384px;
    }

    &.al-B5 {
        background-position: -464px -400px;
    }

    &.al-B4 {
        background-position: -464px -416px;
    }

    &.al-B3 {
        background-position: -464px -432px;
    }

    &.al-B2 {
        background-position: -464px -448px;
    }

    &.al-B1 {
        background-position: 0 -464px;
    }

    &.al-B0 {
        background-position: -16px -464px;
    }

    &.al-AZ {
        background-position: -32px -464px;
    }

    &.al-AY {
        background-position: -48px -464px;
    }

    &.al-AX {
        background-position: -64px -464px;
    }

    &.al-AW {
        background-position: -80px -464px;
    }

    &.al-AV {
        background-position: -96px -464px;
    }

    &.al-AU {
        background-position: -112px -464px;
    }

    &.al-AT {
        background-position: -128px -464px;
    }

    &.al-AS {
        background-position: -144px -464px;
    }

    &.al-AR {
        background-position: -160px -464px;
    }

    &.al-AQ {
        background-position: -176px -464px;
    }

    &.al-AP {
        background-position: -192px -464px;
    }

    &.al-AO {
        background-position: -208px -464px;
    }

    &.al-AN {
        background-position: -224px -464px;
    }

    &.al-AM {
        background-position: -240px -464px;
    }

    &.al-AL {
        background-position: -256px -464px;
    }

    &.al-AK {
        background-position: -272px -464px;
    }

    &.al-AJ {
        background-position: -288px -464px;
    }

    &.al-AI {
        background-position: -304px -464px;
    }

    &.al-AH {
        background-position: -320px -464px;
    }

    &.al-AG {
        background-position: -336px -464px;
    }

    &.al-AF {
        background-position: -352px -464px;
    }

    &.al-AE {
        background-position: -368px -464px;
    }

    &.al-AD {
        background-position: -384px -464px;
    }

    &.al-AC {
        background-position: -400px -464px;
    }

    &.al-AB {
        background-position: -416px -464px;
    }

    &.al-AA {
        background-position: -432px -464px;
    }

    &.al-A9 {
        background-position: -448px -464px;
    }

    &.al-A8 {
        background-position: -464px -464px;
    }

    &.al-A7 {
        background-position: -480px 0;
    }

    &.al-A6 {
        background-position: -480px -16px;
    }

    &.al-A5 {
        background-position: -480px -32px;
    }

    &.al-A4 {
        background-position: -480px -48px;
    }

    &.al-A3 {
        background-position: -480px -64px;
    }

    &.al-A2 {
        background-position: -480px -80px;
    }

    &.al-A1 {
        background-position: -480px -96px;
    }

    &.al-A0 {
        background-position: -480px -112px;
    }

    &.al-9Y {
        background-position: -480px -128px;
    }

    &.al-9X {
        background-position: -480px -144px;
    }

    &.al-9W {
        background-position: -480px -160px;
    }

    &.al-9V {
        background-position: -480px -176px;
    }

    &.al-9U {
        background-position: -480px -192px;
    }

    &.al-9T {
        background-position: -480px -208px;
    }

    &.al-9S {
        background-position: -480px -224px;
    }

    &.al-9R {
        background-position: -480px -240px;
    }

    &.al-9Q {
        background-position: -480px -256px;
    }

    &.al-9P {
        background-position: -480px -272px;
    }

    &.al-9O {
        background-position: -480px -288px;
    }

    &.al-9N {
        background-position: -480px -304px;
    }

    &.al-9M {
        background-position: -480px -320px;
    }

    &.al-9L {
        background-position: -480px -336px;
    }

    &.al-9K {
        background-position: -480px -352px;
    }

    &.al-9J {
        background-position: -480px -368px;
    }

    &.al-9I {
        background-position: -480px -384px;
    }

    &.al-9H {
        background-position: -480px -400px;
    }

    &.al-9G {
        background-position: -480px -416px;
    }

    &.al-9F {
        background-position: -480px -432px;
    }

    &.al-9E {
        background-position: -480px -448px;
    }

    &.al-9D {
        background-position: -480px -464px;
    }

    &.al-9C {
        background-position: 0 -480px;
    }

    &.al-9B {
        background-position: -16px -480px;
    }

    &.al-9A {
        background-position: -32px -480px;
    }

    &.al-8Z {
        background-position: -48px -480px;
    }

    &.al-8Y {
        background-position: -64px -480px;
    }

    &.al-8W {
        background-position: -80px -480px;
    }

    &.al-8V {
        background-position: -96px -480px;
    }

    &.al-8U {
        background-position: -112px -480px;
    }

    &.al-8T {
        background-position: -128px -480px;
    }

    &.al-8S {
        background-position: -144px -480px;
    }

    &.al-8R {
        background-position: -160px -480px;
    }

    &.al-8Q {
        background-position: -176px -480px;
    }

    &.al-8P {
        background-position: -192px -480px;
    }

    &.al-8O {
        background-position: -208px -480px;
    }

    &.al-8N {
        background-position: -224px -480px;
    }

    &.al-8M {
        background-position: -240px -480px;
    }

    &.al-8L {
        background-position: -256px -480px;
    }

    &.al-8K {
        background-position: -272px -480px;
    }

    &.al-8J {
        background-position: -288px -480px;
    }

    &.al-8I {
        background-position: -304px -480px;
    }

    &.al-8H {
        background-position: -320px -480px;
    }

    &.al-8G {
        background-position: -336px -480px;
    }

    &.al-8F {
        background-position: -352px -480px;
    }

    &.al-8E {
        background-position: -368px -480px;
    }

    &.al-8D {
        background-position: -384px -480px;
    }

    &.al-8C {
        background-position: -400px -480px;
    }

    &.al-8B {
        background-position: -416px -480px;
    }

    &.al-8A {
        background-position: -432px -480px;
    }

    &.al-86 {
        background-position: -448px -480px;
    }

    &.al-7Z {
        background-position: -464px -480px;
    }

    &.al-7Y {
        background-position: -480px -480px;
    }

    &.al-7X {
        background-position: -496px 0;
    }

    &.al-7W {
        background-position: -496px -16px;
    }

    &.al-7V {
        background-position: -496px -32px;
    }

    &.al-7U {
        background-position: -496px -48px;
    }

    &.al-7T {
        background-position: -496px -64px;
    }

    &.al-7S {
        background-position: -496px -80px;
    }

    &.al-7R {
        background-position: -496px -96px;
    }

    &.al-7Q {
        background-position: -496px -112px;
    }

    &.al-7P {
        background-position: -496px -128px;
    }

    &.al-7O {
        background-position: -496px -144px;
    }

    &.al-7N {
        background-position: -496px -160px;
    }

    &.al-7M {
        background-position: -496px -176px;
    }

    &.al-7L {
        background-position: -496px -192px;
    }

    &.al-7K {
        background-position: -496px -208px;
    }

    &.al-7J {
        background-position: -496px -224px;
    }

    &.al-7I {
        background-position: -496px -240px;
    }

    &.al-7H {
        background-position: -496px -256px;
    }

    &.al-7G {
        background-position: -496px -272px;
    }

    &.al-7F {
        background-position: -496px -288px;
    }

    &.al-7E {
        background-position: -496px -304px;
    }

    &.al-7D {
        background-position: -496px -320px;
    }

    &.al-7C {
        background-position: -496px -336px;
    }

    &.al-7B {
        background-position: -496px -352px;
    }

    &.al-7A {
        background-position: -496px -368px;
    }

    &.al-6Z {
        background-position: -496px -384px;
    }

    &.al-6Y {
        background-position: -496px -400px;
    }

    &.al-6W {
        background-position: -496px -416px;
    }

    &.al-6V {
        background-position: -496px -432px;
    }

    &.al-6U {
        background-position: -496px -448px;
    }

    &.al-6T {
        background-position: -496px -464px;
    }

    &.al-6S {
        background-position: -496px -480px;
    }

    &.al-6R {
        background-position: 0 -496px;
    }

    &.al-6Q {
        background-position: -16px -496px;
    }

    &.al-6O {
        background-position: -32px -496px;
    }

    &.al-6N {
        background-position: -48px -496px;
    }

    &.al-6M {
        background-position: -64px -496px;
    }

    &.al-6L {
        background-position: -80px -496px;
    }

    &.al-6K {
        background-position: -96px -496px;
    }

    &.al-6J {
        background-position: -112px -496px;
    }

    &.al-6I {
        background-position: -128px -496px;
    }

    &.al-6H {
        background-position: -144px -496px;
    }

    &.al-6G {
        background-position: -160px -496px;
    }

    &.al-6F {
        background-position: -176px -496px;
    }

    &.al-6E {
        background-position: -192px -496px;
    }

    &.al-6D {
        background-position: -208px -496px;
    }

    &.al-6C {
        background-position: -224px -496px;
    }

    &.al-6B {
        background-position: -240px -496px;
    }

    &.al-6A {
        background-position: -256px -496px;
    }

    &.al-5Z {
        background-position: -272px -496px;
    }

    &.al-5Y {
        background-position: -288px -496px;
    }

    &.al-5X {
        background-position: -304px -496px;
    }

    &.al-5W {
        background-position: -320px -496px;
    }

    &.al-5V {
        background-position: -336px -496px;
    }

    &.al-5U {
        background-position: -352px -496px;
    }

    &.al-5T {
        background-position: -368px -496px;
    }

    &.al-5S {
        background-position: -384px -496px;
    }

    &.al-5R {
        background-position: -400px -496px;
    }

    &.al-5Q {
        background-position: -416px -496px;
    }

    &.al-5P {
        background-position: -432px -496px;
    }

    &.al-5O {
        background-position: -448px -496px;
    }

    &.al-5N {
        background-position: -464px -496px;
    }

    &.al-5M {
        background-position: -480px -496px;
    }

    &.al-5L {
        background-position: -496px -496px;
    }

    &.al-5K {
        background-position: -512px 0;
    }

    &.al-5J {
        background-position: -512px -16px;
    }

    &.al-5I {
        background-position: -512px -32px;
    }

    &.al-5H {
        background-position: -512px -48px;
    }

    &.al-5G {
        background-position: -512px -64px;
    }

    &.al-5F {
        background-position: -512px -80px;
    }

    &.al-5E {
        background-position: -512px -96px;
    }

    &.al-5D {
        background-position: -512px -112px;
    }

    &.al-5C {
        background-position: -512px -128px;
    }

    &.al-5B {
        background-position: -512px -144px;
    }

    &.al-5A {
        background-position: -512px -160px;
    }

    &.al-4Z {
        background-position: -512px -176px;
    }

    &.al-4Y {
        background-position: -512px -192px;
    }

    &.al-4X {
        background-position: -512px -208px;
    }

    &.al-4W {
        background-position: -512px -224px;
    }

    &.al-4V {
        background-position: -512px -240px;
    }

    &.al-4U {
        background-position: -512px -256px;
    }

    &.al-4T {
        background-position: -512px -272px;
    }

    &.al-4S {
        background-position: -512px -288px;
    }

    &.al-4R {
        background-position: -512px -304px;
    }

    &.al-4Q {
        background-position: -512px -320px;
    }

    &.al-4P {
        background-position: -512px -336px;
    }

    &.al-4O {
        background-position: -512px -352px;
    }

    &.al-4N {
        background-position: -512px -368px;
    }

    &.al-4M {
        background-position: -512px -384px;
    }

    &.al-4L {
        background-position: -512px -400px;
    }

    &.al-4K {
        background-position: -512px -416px;
    }

    &.al-4J {
        background-position: -512px -432px;
    }

    &.al-4I {
        background-position: -512px -448px;
    }

    &.al-4H {
        background-position: -512px -464px;
    }

    &.al-4G {
        background-position: -512px -480px;
    }

    &.al-4F {
        background-position: -512px -496px;
    }

    &.al-4E {
        background-position: 0 -512px;
    }

    &.al-4D {
        background-position: -16px -512px;
    }

    &.al-4C {
        background-position: -32px -512px;
    }

    &.al-4B {
        background-position: -48px -512px;
    }

    &.al-4A {
        background-position: -64px -512px;
    }

    &.al-3Z {
        background-position: -80px -512px;
    }

    &.al-3Y {
        background-position: -96px -512px;
    }

    &.al-3X {
        background-position: -112px -512px;
    }

    &.al-3W {
        background-position: -128px -512px;
    }

    &.al-3V {
        background-position: -144px -512px;
    }

    &.al-3U {
        background-position: -160px -512px;
    }

    &.al-3T {
        background-position: -176px -512px;
    }

    &.al-3S {
        background-position: -192px -512px;
    }

    &.al-3R {
        background-position: -208px -512px;
    }

    &.al-3Q {
        background-position: -224px -512px;
    }

    &.al-3P {
        background-position: -240px -512px;
    }

    &.al-3O {
        background-position: -256px -512px;
    }

    &.al-3N {
        background-position: -272px -512px;
    }

    &.al-3M {
        background-position: -288px -512px;
    }

    &.al-3L {
        background-position: -304px -512px;
    }

    &.al-3K {
        background-position: -320px -512px;
    }

    &.al-3J {
        background-position: -336px -512px;
    }

    &.al-3I {
        background-position: -352px -512px;
    }

    &.al-3H {
        background-position: -368px -512px;
    }

    &.al-3G {
        background-position: -384px -512px;
    }

    &.al-3F {
        background-position: -400px -512px;
    }

    &.al-3E {
        background-position: -416px -512px;
    }

    &.al-3D {
        background-position: -432px -512px;
    }

    &.al-3C {
        background-position: -448px -512px;
    }

    &.al-3B {
        background-position: -464px -512px;
    }

    &.al-3A {
        background-position: -480px -512px;
    }

    &.al-2Z {
        background-position: -496px -512px;
    }

    &.al-2Y {
        background-position: -512px -512px;
    }

    &.al-2W {
        background-position: -528px 0;
    }

    &.al-2V {
        background-position: -528px -16px;
    }

    &.al-2U {
        background-position: -528px -32px;
    }

    &.al-2T {
        background-position: -528px -48px;
    }

    &.al-2S {
        background-position: -528px -64px;
    }

    &.al-2R {
        background-position: -528px -80px;
    }

    &.al-2Q {
        background-position: -528px -96px;
    }

    &.al-2P {
        background-position: -528px -112px;
    }

    &.al-2O {
        background-position: -528px -128px;
    }

    &.al-2N {
        background-position: -528px -144px;
    }

    &.al-2M {
        background-position: -528px -160px;
    }

    &.al-2L {
        background-position: -528px -176px;
    }

    &.al-2K {
        background-position: -528px -192px;
    }

    &.al-2J {
        background-position: -528px -208px;
    }

    &.al-2I {
        background-position: -528px -224px;
    }

    &.al-2H {
        background-position: -528px -240px;
    }

    &.al-2G {
        background-position: -528px -256px;
    }

    &.al-2F {
        background-position: -528px -272px;
    }

    &.al-2E {
        background-position: -528px -288px;
    }

    &.al-2D {
        background-position: -528px -304px;
    }

    &.al-2C {
        background-position: -528px -320px;
    }

    &.al-2B {
        background-position: -528px -336px;
    }

    &.al-2A {
        background-position: -528px -352px;
    }

    &.al-1Z {
        background-position: -528px -368px;
    }

    &.al-1Y {
        background-position: -528px -384px;
    }

    &.al-1X {
        background-position: -528px -400px;
    }

    &.al-1W {
        background-position: -528px -416px;
    }

    &.al-1V {
        background-position: -528px -432px;
    }

    &.al-1U {
        background-position: -528px -448px;
    }

    &.al-1T {
        background-position: -528px -464px;
    }

    &.al-1S {
        background-position: -528px -480px;
    }

    &.al-1R {
        background-position: -528px -496px;
    }

    &.al-1Q {
        background-position: -528px -512px;
    }

    &.al-1P {
        background-position: 0 -528px;
    }

    &.al-1N {
        background-position: -16px -528px;
    }

    &.al-1M {
        background-position: -32px -528px;
    }

    &.al-1L {
        background-position: -48px -528px;
    }

    &.al-1K {
        background-position: -64px -528px;
    }

    &.al-1J {
        background-position: -80px -528px;
    }

    &.al-1I {
        background-position: -96px -528px;
    }

    &.al-1H {
        background-position: -112px -528px;
    }

    &.al-1G {
        background-position: -128px -528px;
    }

    &.al-1F {
        background-position: -144px -528px;
    }

    &.al-1E {
        background-position: -160px -528px;
    }

    &.al-1D {
        background-position: -176px -528px;
    }

    &.al-1C {
        background-position: -192px -528px;
    }

    &.al-1B {
        background-position: -208px -528px;
    }

    &.al-1A {
        background-position: -224px -528px;
    }

    &.al-0W {
        background-position: -240px -528px;
    }

    &.al-0V {
        background-position: -256px -528px;
    }

    &.al-0Q {
        background-position: -272px -528px;
    }

    &.al-0P {
        background-position: -288px -528px;
    }

    &.al-0K {
        background-position: -304px -528px;
    }

    &.al-0J {
        background-position: -320px -528px;
    }

    &.al-0B {
        background-position: -336px -528px;
    }

    &.al-0A {
        background-position: -352px -528px;
    }
}
