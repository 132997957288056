.page-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.page {
  position: absolute;
  width: 750px;
  top: 100px;
  left: 50%;
  margin-left: -375px;
  margin-bottom: 50px;
  z-index: 4;

  background: rgba(#fff,0.90);
  border: 1px solid darken($border-color, 5%);
  box-shadow: 0 2px 42px 0 rgba(0, 0, 0, 0.05), 0 0 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 6px;


  .page-header {
    padding: 20px;
    background-color: $background-primary-color;
    border-bottom: 1px solid darken($border-color, 5%);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .page-nav {
      a {
        color: $secondary-color;
        font-size: 16px;
        font-weight: bold;
        .icon {
          font-size: 12px;
        }
        &:hover {
          color: darken($secondary-color, 10%);
        }
      }
    }

    h1 {
      font-weight: bold;
      color: $primary-color;
      font-size: 24px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }

  .page-content {
    color: $text-default-color;
    padding: 20px;
    font-size: 14px;

    h2 {
      font-weight: bold;
      color: $secondary-color;
      font-size: 18px;
      margin-top: 5px;
      margin-bottom: 3px;
    }

    h3 {
      font-weight: bold;
      color: $text-default-color;
      font-size: 16px;
      margin-top: 5px;
      margin-bottom: 3px;
    }
  }
}

@media (max-width: 760px) {
  .panel-logo {
    width: 40px;
    height: 43px;
    background-size: 40px 43px;
  }
  .page {
    width: 100%;
    min-height: 100%;
    margin-left: 0;
    top: 0;
    border: 0;
    left: 0;
    border-radius: 0;
  }
}

table.c15 {
  border: 1px solid;
  border-collapse: collapse;
  width: 100%;
  text-align: center;

  td {
    border: 1px solid;
    border-collapse: collapse;
  }
}
.alternatives img {
  width: 100%;
  margin-top: 20px;
}
